import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useProvisionTemplateToTargetOrgs = () => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();
  return useMutation(
    async (
      { crId, payload, lineage },
    ) => axiosInstance.post(cleanRoom(orgId).provisionTemplateToTargetOrgs(crId), {
      targetOrgIdCredentialIdMapping: payload,
      lineage,
    }),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Successfully clone clean room template',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
      },
    },
  );
};
