import amcLogo from 'assets/data-sources/amazon-marketing-cloud.svg';
import awsEcrLogo from 'assets/data-sources/aws-ecr.svg';
import awsS3Logo from 'assets/data-sources/aws-s3.svg';
import azureLogo from 'assets/data-sources/azure.svg';
import databricksLogo from 'assets/data-sources/databricks.svg';
import dockerLogo from 'assets/data-sources/docker.svg';
import faaLogo from 'assets/data-sources/facebook-advanced-analytics.svg';
import gcsLogo from 'assets/data-sources/gcs.svg';
import googleAdManagerLogo from 'assets/data-sources/google-ad-manager.svg';
import googleAdhLogo from 'assets/data-sources/google-adh.svg';
import googleBigQueryLogo from 'assets/data-sources/google-big-query.svg';
import hubspotLogo from 'assets/data-sources/hubspot.png';
import icebergLogo from 'assets/data-sources/iceberg.svg';
import linkedinCsvCatalogLogo from 'assets/data-sources/linkedin-csv-catalog.svg';
import linkedinMarketingLogo from 'assets/data-sources/linkedin-marketing.svg';
import salesforceLogo from 'assets/data-sources/salesforce.svg';
import snowflakeLogo from 'assets/data-sources/snowflake.svg';
import tddLogo from 'assets/data-sources/the-trade-desk.svg';
import { habuColors } from 'Theme';


export const crDatasetStatus = {
  CONFIGURATION_REQUIRED: { key: 'CONFIGURATION_REQUIRED', label: 'Configuration Required', color: habuColors.primary[90] },
  RECONFIGURATION_REQUIRED: { key: 'RECONFIGURATION_REQUIRED', label: 'Reconfiguration Required', color: habuColors.primary[90] },
  CONFIGURED: { key: 'CONFIGURED', label: 'Provisioned', color: habuColors.signal.success[80] },
  MATERIALIZED: { key: 'MATERIALIZED', label: 'Provisioned with Materialized View', color: habuColors.signal.success[80] },
};

export const dataSetType = {
  AD_ENGAGEMENT: { key: 'AdEngagement', label: 'Ad Engagement' },
  AD_LOGS: { key: 'AdLogs', label: 'Ad Logs' },
  ADS_DATA_FEED: { key: 'AdsDataFeed', label: 'Google Ads Data Hub: Event-Level Ad Campaign Data' },
  AMC_ADS_DATA_FEED: { key: 'AMCAdsDataFeed', label: 'Amazon Marketing Cloud: Event-Level Ad Campaign Data' },
  BINARY_DATA: { key: 'BinaryData', label: 'Binary Data' },
  CAMPAIGN_DATA: { key: 'Campaign', label: 'Campaign Data' },
  CAMPAIGN_MAPPING: { key: 'CampaignMapping', label: 'Campaign Mapping' },
  CODE_CONTAINER: { key: 'CodeContainer', label: 'Code Container', enableMapVarStep: true },
  DATABRICKS_CONTAINER: { key: 'DatabricksContainer', label: 'Databricks Container', enableMapVarStep: true },
  DATABRICKS_NOTEBOOK: { key: 'DatabricksNotebook', label: 'Databricks Notebook' },
  SERVICE_CONTAINER: { key: 'ServiceContainer', label: 'Service Container' },
  CONVERSION_EVENTS: { key: 'ConversionEvents', label: 'Conversion Events' },
  CRM: { key: 'CRM', label: 'CRM Data' },
  IDENTITY_GRAPH: { key: 'IDENTITY_GRAPH', label: 'Identity Graph' },
  FB_AA_DATA_FEED: { key: 'AADataFeed', label: 'Facebook: Advanced Analytics Data' },
  GENERIC: { key: 'Generic', label: 'Generic' },
  GOOGLE_CLOUD_BIG_QUERY: { key: 'GoogleCloudBigQuery', label: 'Google Cloud Big Query' },
  LINKED_IN_MARKETING_SOLUTIONS_CONFIG_DATA: { key: 'LinkedInMarketingSolutionsConfigData', label: 'LinkedIn Marketing Solutions Config Data' },
  ML_MODEL: { key: 'MLModel', label: 'Machine Learning Model' },
  MEMBER_PROFILE: { key: 'MemberProfile', label: 'Member Profile' },
  METADATA: { key: 'Metadata', label: 'Metadata' },
  OFFLINE_CONVERSIONS: { key: 'OfflineConversions', label: 'Offline Conversions' },
  PAGE_DATA: { key: 'PageData', label: 'Page Data' },
  TRAIN_MODEL: { key: 'TrainModel', label: 'Model Training' },
  TRANSACTION: { key: 'Transaction', label: 'Transaction Data' },
  TTDREDSFeed: { key: 'TTDREDSFeed', label: 'The Trade Desk: Raw Event Data Stream (REDS) Feed' },
  TTDREDSMetadata: { key: 'TTDREDSMetadata', label: 'The Trade Desk: Raw Event Data Stream (REDS) Metadata' },
  USER_DATA: { key: 'UserData', label: 'User Data' },
  USER_SEGMENT_MAP: { key: 'UserSegmentMap', label: 'User-Segment Map' },
};

export const dataSources = {
  SNOWFLAKE_PATTERN: { key: 'SnowflakeDataConnection', label: 'Snowflake Data Connection' },
  SNOWFLAKE_S3_PATTERN: { key: 'SnowflakeS3DataConnection', label: 'Snowflake S3 Data Connection' },
  SNOWFLAKE_GCS_PATTERN: { key: 'SnowflakeGCSDataConnection', label: 'Snowflake GCS Data Connection' },
  SNOWFLAKE_AZURE_PATTERN: { key: 'SnowflakeAzureDataConnection', label: 'Snowflake Azure Data Connection' },
  SNOWFLAKE_TABLE: { key: 'SnowflakeTable', label: 'Snowflake Table' },
  HABU_SNOWFLAKE_PATTERN: { key: 'HabuSnowflake', label: 'Habu Snowflake' },
  ADH_HANDLER_PATTERN: { key: 'ADHHandler', label: 'ADH handler' },
  FACEBOOK_AA_HANDLER_PATTERN: { key: 'FacebookAAHandler', label: 'Facebook AA handler' },
  DATABRICKS_PATTERN: { key: 'DatabricksDataConnection', label: 'Databricks Data Connection' },
  GOOGLE_CLOUD_AUTHORIZED_VIEW: { key: 'GoogleCloudAuthorizedView', label: 'Google Cloud Authorized View' },
  NOTEBOOKS: { key: 'Notebooks', label: 'Notebooks' },
  CSV_CATALOG: { key: 'CSV_CATALOG', label: 'CSV Catalog' },
  FACEBOOK_ADVANCED_ANALYTICS: { key: 'FACEBOOK_ADVANCED_ANALYTICS', label: 'Facebook Advanced Analytics' },
  ADS_DATA_HUB: { key: 'ADS_DATA_HUB', label: 'Google Ads Data Hub' },
  HABU_CUSTOM_AWS: { key: 'HABU_CUSTOM_AWS', label: 'Habu Custom AWS' },
  HABU_AWS: { key: 'HABU_AWS', label: 'Habu AWS S3' },
  CLIENT_AWS: { key: 'CLIENT_AWS', label: 'Client AWS S3' },
  CLIENT_GCS_SA: { key: 'CLIENT_GCS_SA', label: 'Google Cloud Storage(with SA)' },
  CLIENT_GCS: { key: 'CLIENT_GCS', label: 'Google Cloud Storage' },
};

export const runStatus = {
  COMPLETE: { key: 'COMPLETE', label: 'Complete', color: 'green' },
  COMPLETED: { key: 'COMPLETED', label: 'Completed', color: 'green' },
  FAILED: { key: 'FAILED', label: 'Failed', color: 'red' },
  QUEUED: { key: 'QUEUED', label: 'Queued', color: 'orange' },
  RUNNING: { key: 'RUNNING', label: 'Running', color: 'orange' },
  PROCESSING: { key: 'PROCESSING', label: 'Processing', color: 'orange' },
  PARTNER_QUEUED: { key: 'PARTNER_QUEUED', label: 'Partner Queued', color: 'orange' },
  PARTNER_RUNNING: { key: 'PARTNER_RUNNING', label: 'Partner Running', color: 'orange' },
  PARTNER_COMPLETED: { key: 'PARTNER_COMPLETED', label: 'Partner Completed', color: 'orange' },
  PARTNER_FAILED: { key: 'PARTNER_FAILED', label: 'Partner Failed', color: 'red' },
  RUN_STATUS_UNKNOWN: { key: 'RUN_STATUS_UNKNOWN', label: '-', color: 'gray' },
  CANCELED: { key: 'CANCELED', label: 'Canceled', color: 'orange' },
};

export const refreshInterval = {
  IN_SYNC: { key: 'IN_SYNC', label: 'In Sync' },
  ONE_TIME: { key: 'ONE_TIME', label: 'One Time' },
};

export const stage = {
  COMPUTED: { key: 'COMPUTED', label: 'Computed', color: 'green' },
  CONFIGURATION_COMPLETE: { key: 'CONFIGURATION_COMPLETE', label: 'Completed', color: 'green' },
  CONFIGURATION_FAILED: { key: 'CONFIGURATION_FAILED', label: 'Failed', color: 'red' },
  CONSENT_REQUIRED: { key: 'CONSENT_REQUIRED', label: 'Consent Required', color: 'red' },
  DRAFT_JOB: { key: 'DRAFT_JOB', label: 'Draft', color: 'gray' },
  MAPPING_REQUIRED: { key: 'MAPPING_REQUIRED', label: 'Mapping Required', color: 'red' },
  NEW: { key: 'NEW', label: 'New', color: 'orange' },
  OPT_IN: { key: 'OPT_IN', label: 'Opted In', color: 'green' },
  OPT_OUT: { key: 'OPT_OUT', label: 'Opted Out', color: 'red' },
  PROCESSING: { key: 'PROCESSING', label: 'Processing', color: 'yellow' },
  STAGE_UNKNOWN: { key: 'STAGE_UNKNOWN', label: '-', color: 'gray' },
  VERIFY_ACCESS: { key: 'VERIFY_ACCESS', label: 'Verify Access', color: 'gray' },
  INITIALIZATION: { key: 'INITIALIZATION', label: 'Initializing', color: 'gray' },
  VERIFICATION_IN_PROGRESS: { key: 'VERIFICATION_IN_PROGRESS', label: 'Verifying', color: 'gray' },
  WAITING_FOR_FILE: { key: 'WAITING_FOR_FILE', label: 'Waiting For File', color: 'red' },
  WAITING_FOR_MAPPING: { key: 'WAITING_FOR_MAPPING', label: 'Waiting For Mapping', color: 'red' },
};

export const status = {
  ACTIVE: { key: 'ACTIVE', label: 'Active', color: 'green' },
  DRAFT: { key: 'DRAFT', label: 'Draft', color: 'gray' },
  PAUSED: { key: 'PAUSED', label: 'Paused', color: 'red' },
  STATUS_UNKNOWN: { key: 'STATUS_UNKNOWN', label: '-', color: 'gray' },
};

export const crQuestionStage = {
  STAGE_UNKNOWN: { key: 'STAGE_UNKNOWN', label: '-', color: 'gray' },
  MISSING_DATASETS: { key: 'MISSING_DATASETS', label: 'Missing Datasets', color: 'red' },
  MISSING_DATASETS_RECONFIGURE: { key: 'MISSING_DATASETS_RECONFIGURE', label: 'Assigned Dataset modified, Please reconfigure.', color: 'gray' },
  MISSING_OWNER_DATASET: { key: 'MISSING_OWNER_DATASET', label: 'Missing Owner Dataset', color: 'red' },
  MISSING_OWNER_DATASET_FIELDS: { key: 'MISSING_OWNER_DATASET_FIELDS', label: 'Missing Owner Dataset Fields', color: 'red' },
  MISSING_PARTNER_DATASET_FIELDS: { key: 'MISSING_PARTNER_DATASET_FIELDS', label: 'Missing Partner Dataset Fields', color: 'red' },
  MISSING_PARTNER_DATASET: { key: 'MISSING_PARTNER_DATASET', label: 'Missing Partner Dataset', color: 'red' },
  MISSING_ENV_VAR_MAPPING: { key: 'MISSING_ENV_VAR_MAPPING', label: 'Map Environment Variables', color: 'red' },
  IN_PROGRESS: { key: 'IN_PROGRESS', label: 'In Progress', color: 'orange' },
  PENDING: { key: 'PENDING', label: 'Pending', color: 'orange' },
  READY: { key: 'READY', label: 'Ready', color: 'green' },
};

export const crPartnerStage = {
  UNKNOWN: { key: 'UNKNOWN', label: '-', color: 'gray' },
  PENDING: { key: 'PENDING', label: 'Pending', color: 'orange' },
  IN_PROGRESS: { key: 'IN_PROGRESS', label: 'In Progress', color: 'orange' },
  COMPLETE: { key: 'COMPLETE', label: 'Complete', color: 'green' },
  FAILED: { key: 'FAILED', label: 'Failed', color: 'red' },
};

export const crqAnalysisRuleStatus = {
  RUNNING: { key: 'RUNNING', label: 'Validating' },
  QUEUED: { key: 'QUEUED', label: 'Validating' },
  COMPLETED: { key: 'COMPLETED', label: 'Completed' },
  FAILED: { key: 'FAILED', label: 'Failed' },
};

export const tgDataTypes = {
  BIGINT: { key: 'BIGINT', value: 'Integer' },
  BOOLEAN: { key: 'BOOLEAN', value: 'Boolean' },
  DATE: { key: 'DATE', value: 'Date' },
  DOUBLE: { key: 'DOUBLE', value: 'Double' },
  INTEGER: { key: 'INTEGER', value: 'Integer' },
  STRING: { key: 'STRING', value: 'String' },
  TIMESTAMP: { key: 'TIMESTAMP', value: 'Timestamp' },
};

export const goDataTypes = {
  bool: { key: '', dataType: tgDataTypes.BOOLEAN.key },
  int: { key: 'int', dataType: tgDataTypes.INTEGER.key },
  int8: { key: 'int8', dataType: tgDataTypes.INTEGER.key },
  int16: { key: 'int16', dataType: tgDataTypes.INTEGER.key },
  int32: { key: 'int32', dataType: tgDataTypes.INTEGER.key },
  int64: { key: 'int64', dataType: tgDataTypes.INTEGER.key },
  uint: { key: 'uint', dataType: tgDataTypes.INTEGER.key },
  uint8: { key: 'uint8', dataType: tgDataTypes.INTEGER.key },
  uint16: { key: 'uint16', dataType: tgDataTypes.INTEGER.key },
  uint32: { key: 'uint32', dataType: tgDataTypes.INTEGER.key },
  uint64: { key: 'uint64', dataType: tgDataTypes.INTEGER.key },
  uintptr: { key: 'uintptr', dataType: tgDataTypes.INTEGER.key },
  float32: { key: 'float32', dataType: tgDataTypes.INTEGER.key },
  float64: { key: 'float64', dataType: tgDataTypes.INTEGER.key },
  complex64: { key: 'complex64', dataType: tgDataTypes.INTEGER.key },
  complex128: { key: 'complex128', dataType: tgDataTypes.INTEGER.key },
  array: { key: 'array', dataType: tgDataTypes.STRING.key },
  chan: { key: 'chan', dataType: tgDataTypes.STRING.key },
  func: { key: 'func', dataType: tgDataTypes.STRING.key },
  interface: { key: 'interface', dataType: tgDataTypes.STRING.key },
  map: { key: 'map', dataType: tgDataTypes.STRING.key },
  ptr: { key: 'ptr', dataType: tgDataTypes.STRING.key },
  slice: { key: 'slice', dataType: tgDataTypes.STRING.key },
  string: { key: 'string', dataType: tgDataTypes.STRING.key },
  struct: { key: 'struct', dataType: tgDataTypes.STRING.key },
  unsafePointer: { key: 'unsafePointer', dataType: tgDataTypes.STRING.key },
};

export const SNOWFLAKE_CLI_STATUS = {
  NONE: { key: 'NONE', value: 0 },
  WARNING: { key: 'WARNING', value: 1 },
  ERROR: { key: 'ERROR', value: 2 },
  NOT_FOUND: { key: 'NOT_FOUND', value: 3 },
};

export const CLEANROOM_CLI_VERSION_STATUS = {
  NONE: 'NONE',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  NOT_FOUND: 'NOT_FOUND',
};

export const FLOW_STATUS = {
  FAILED: {
    key: 'FAILED', label: 'Failed', color: '#FCE3D5', stageTextColor: '#C62828',
  },
  DRAFT: {
    key: 'DRAFT', label: 'Draft', color: '#EEEEEE', stageTextColor: '#696969',
  },
  MISSING_DATASETS: {
    key: 'MISSING_DATASETS', label: 'Missing Datasets', color: '#EEEEEE', stageTextColor: '#696969',
  },
  STAGE_UNKNOWN: { key: 'STAGE_UNKNOWN', label: '-', color: 'gray' },
  READY: {
    key: 'READY', label: 'Ready to Run', color: '#E2F8D9', stageTextColor: '#1B5E20',
  },
};

const yellowFlowStageColor = {
  color: '#FFF9D7',
  stageTextColor: '#c49717',
};

export const FLOW_STAGE = {
  STAGE_UNKNOWN: {
    key: 'STAGE_UNKNOWN', label: '-', color: 'gray', stageTextColor: 'grey',
  },
  MISSING_DATASETS: {
    key: 'MISSING_DATASETS', label: 'Missing Datasets', ...yellowFlowStageColor,
  },
  MISSING_DATASETS_RECONFIGURE: {
    key: 'MISSING_DATASETS_RECONFIGURE', label: 'Assigned Dataset modified, Please reconfigure.', ...yellowFlowStageColor,
  },
  MISSING_OWNER_DATASET: {
    key: 'MISSING_OWNER_DATASET', label: 'Missing Owner Dataset', ...yellowFlowStageColor,
  },
  MISSING_OWNER_DATASET_FIELDS: {
    key: 'MISSING_OWNER_DATASET_FIELDS', label: 'Missing Owner Dataset Fields', ...yellowFlowStageColor,
  },
  MISSING_PARTNER_DATASET_FIELDS: {
    key: 'MISSING_PARTNER_DATASET_FIELDS', label: 'Missing Partner Dataset Fields', ...yellowFlowStageColor,
  },
  MISSING_PARTNER_DATASET: {
    key: 'MISSING_PARTNER_DATASET', label: 'Missing Partner Dataset', ...yellowFlowStageColor,
  },
  MISSING_ENV_VAR_MAPPING: {
    key: 'MISSING_ENV_VAR_MAPPING', label: 'Map Environment Variables', ...yellowFlowStageColor,
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS', label: 'In Progress', color: '#EEEEEE', stageTextColor: '#696969',
  },
  PENDING: {
    key: 'PENDING', label: 'Pending', color: 'orange', stageTextColor: 'orange',
  },
  READY: {
    key: 'READY', label: 'Ready', color: '#E2F8D9', stageTextColor: '#1B5E20',
  },
};

export const FLOW_RUN_STATUS = {
  COMPLETE: {
    key: 'COMPLETE', label: 'Complete', color: '#E2F8D9', textColor: '#1B5E20',
  },
  COMPLETED: {
    key: 'COMPLETED', label: 'Completed', color: '#E2F8D9', textColor: '#1B5E20',
  },
  FAILED: {
    key: 'FAILED', label: 'Failed', color: '#FCE3D5', textColor: '#C62828',
  },
  QUEUED: { key: 'QUEUED', label: 'Queued', ...yellowFlowStageColor },
  RUNNING: { key: 'RUNNING', label: 'Running', ...yellowFlowStageColor },
  PROCESSING: { key: 'PROCESSING', label: 'Processing', ...yellowFlowStageColor },
  RUN_STATUS_UNKNOWN: {
    key: 'RUN_STATUS_UNKNOWN', label: '-', color: 'gray', textColor: 'grey',
  },
  FLOW_PAUSED: { key: 'FLOW_PAUSED', label: 'Paused', ...yellowFlowStageColor },
};

export const dataSourcesConfig = {
  ADS_DATA_HUB: {
    key: 'ADS_DATA_HUB',
    label: 'Google Ads Data Hub',
    logoSrc: googleAdhLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/google-ads-data-hub-configuration.html',
    preConfig: {
      message: 'Have you enabled the Ads Data Hub API and a Service Account? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/google-ads-data-hub-configuration.html',
    },
  },
  AMAZON_MARKETING_CLOUD: {
    key: 'AMAZON_MARKETING_CLOUD',
    label: 'Amazon Marketing Cloud',
    logoSrc: amcLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-amazon-marketing-cloud.html',
    preConfig: {
      message: 'Have you done a configuration call with Habu? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/configure-amazon-marketing-cloud.html',
    },
  },
  AMAZON_MARKETING_CLOUD_ADS_API: {
    key: 'AMAZON_MARKETING_CLOUD_ADS_API',
    label: 'Amazon Marketing Cloud ADS API',
    logoSrc: amcLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-amazon-marketing-cloud.html',
    preConfig: {
      message: 'Have you done a configuration call with Habu? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/configure-amazon-marketing-cloud.html',
    },
  },
  AWS_ECR_CONTAINER: {
    key: 'AWS_ECR_CONTAINER',
    label: 'AWS ECR Container',
    logoSrc: awsEcrLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/using-code-containers.html#configure-a-data-connection',
    preConfig: {
      message: 'Have you configured an image? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/using-code-containers.html#configure-a-data-connection',
    },
  },
  AWS_GLUE: {
    key: 'AWS_GLUE',
    label: 'AWS Glue Data Catalog',
    logoSrc: amcLogo,
    docLink: 'https://habu.zendesk.com/hc/en-us/articles/23956488396301-Configuring-AWS-Roles-for-AWS-Clean-Rooms',
  },
  CLIENT_AWS: {
    key: 'CLIENT_AWS',
    label: 'AWS S3',
    logoSrc: awsS3Logo,
    docLink: 'https://habu.zendesk.com/hc/en-us/articles/10272084832909-Configuring-an-Amazon-Web-Services-AWS-Data-Connection-Customer-hosted-',
  },
  CLIENT_AZURE_STORAGE: {
    key: 'CLIENT_AZURE_STORAGE',
    label: 'Azure',
    logoSrc: azureLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-an-azure-data-connection.html',
    preConfig: {
      message: 'Have you generated an Azure SAS Token? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/configure-an-azure-data-connection.html',
    },
  },
  CLIENT_GCS: {
    key: 'CLIENT_GCS',
    label: 'Google Cloud Storage',
    logoSrc: gcsLogo,
    docLink: 'https://habu.zendesk.com/hc/en-us/articles/10129828454413-Configuring-a-Google-Cloud-Storage-Data-Connection-Customer-Hosted-',
    preConfig: {
      message: 'Have you configured a Google Service Account? This is required to proceed.',
      docLink: 'https://habu.zendesk.com/hc/en-us/articles/10129828454413-Configuring-a-Google-Cloud-Storage-Data-Connection-Customer-Hosted-',
    },
  },
  CLIENT_GCS_SA: {
    key: 'CLIENT_GCS_SA',
    label: 'Google Cloud Storage',
    logoSrc: gcsLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-a-google-cloud-storage-data-connection--customer-hosted-.html',
    preConfig: {
      message: 'Have you configured a Google Service Account? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/configure-a-google-cloud-storage-data-connection--customer-hosted-.html',
    },
  },
  CONTAINER: {
    key: 'CONTAINER',
    label: 'Docker',
    logoSrc: dockerLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/using-code-containers.html',
    preConfig: {
      message: 'Have you configured an image? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/using-code-containers.html',
    },
  },
  CSV_CATALOG: {
    key: 'CSV_CATALOG',
    label: 'CSV Data Catalog',
    logoSrc: linkedinCsvCatalogLogo,
  },
  CUSTOMER_AWS: {
    key: 'CUSTOMER_AWS',
    label: 'Habu AWS',
    logoSrc: awsS3Logo,
    docLink: 'https://habu.zendesk.com/hc/en-us/articles/10271937608717-Configuring-an-Amazon-Web-Services-AWS-Data-Connection-Habu-hosted-',
  },
  DATABRICKS_DATA_CONNECTION: {
    key: 'DATABRICKS_DATA_CONNECTION',
    label: 'Databricks',
    logoSrc: databricksLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-a-databricks-pattern-data-connection.html',
    preConfig: {
      message: 'Have you installed Habu’s Databricks CLI Framework? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/configure-a-databricks-pattern-data-connection.html',
    },
  },
  DATABRICKS_DELTA_TABLE_CONNECTION: {
    key: 'DATABRICKS_DELTA_TABLE_CONNECTION',
    label: 'Databricks Delta Table Connection',
    logoSrc: databricksLogo,
  },
  FACEBOOK_ADVANCED_ANALYTICS: {
    key: 'FACEBOOK_ADVANCED_ANALYTICS',
    label: 'Facebook Advanced Analytics',
    logoSrc: faaLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-facebook-advanced-analytics.html',
    preConfig: {
      message: 'Have you configured CAPI support? This is required to proceed.',
      docLink: 'https://docs.liveramp.com/clean-room/en/configure-facebook-advanced-analytics.html',
    },
  },
  GAM_LOGS: {
    key: 'GAM_LOGS',
    label: 'Google Ad Manager Logs',
    logoSrc: googleAdManagerLogo,
  },
  GOOGLE_CLOUD_AUTHORIZED_VIEW: {
    key: 'GOOGLE_CLOUD_AUTHORIZED_VIEW',
    label: 'Google Big Query',
    logoSrc: googleBigQueryLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-a-bigquery-data-connection-for-a-hybrid-clean-room.html',
  },
  GOOGLE_CLOUD_BIGQUERY: {
    key: 'GOOGLE_CLOUD_BIGQUERY',
    label: 'Google Cloud Big Query',
    logoSrc: googleBigQueryLogo,
    docLink: 'https://www.google.com/url?q=https://habu.zendesk.com/hc/en-us/articles/23154273846157-Configuring-a-BigQuery-Pattern-Data-Connection&sa=D&source=editors&ust=1708424438092122&usg=AOvVaw1acui7xwq69qoBHqAxm4Oq',
  },
  HABU_AMC: {
    key: 'HABU_AMC',
    label: 'LiveRamp-Hosted Amazon Marketing Cloud',
    logoSrc: amcLogo,
  },
  HABU_AWS: {
    key: 'HABU_AWS',
    label: 'LiveRamp-Hosted AWS S3',
    logoSrc: awsS3Logo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-an-amazon-web-services-data-connection--liveramp-hosted-.html',
  },
  HABU_CUSTOM_AWS: {
    key: 'HABU_CUSTOM_AWS',
    label: 'Habu Custom AWS',
    logoSrc: awsS3Logo,
  },
  HABU_GCS: {
    key: 'HABU_GCS',
    label: 'LiveRamp-Hosted Google Cloud Storage',
    logoSrc: gcsLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-a-google-cloud-storage-data-connection--liveramp-hosted-.html',
  },
  HABU_LOCAL: {
    key: 'HABU_LOCAL',
    label: 'Local Upload',
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-a-data-connection-using-a-csv-upload.html#create-a-data-connection-39',
  },
  HABU_SNOWFLAKE: {
    key: 'HABU_SNOWFLAKE',
    label: 'LiveRamp-Hosted Snowflake',
    logoSrc: snowflakeLogo,
    docLink: 'https://habu.zendesk.com/hc/en-us/articles/15724706206605-Configuring-a-Snowflake-Hybrid-Data-Connection',
  },
  HUBSPOT: {
    key: 'HUBSPOT',
    label: 'Hubspot',
    logoSrc: hubspotLogo,
  },
  LINKEDIN_MARKETING_SOLUTION: {
    key: 'LINKEDIN_MARKETING_SOLUTION',
    label: 'LinkedIn Marketing Solution',
    logoSrc: linkedinMarketingLogo,
  },
  SALESFORCE: {
    key: 'SALESFORCE',
    label: 'Salesforce',
    logoSrc: salesforceLogo,
    docLink: 'https://docs.liveramp.com/clean-room/en/configure-a-salesforce-hybrid-data-connection.html',
  },
  Snowflake: {
    key: 'Snowflake',
    label: 'Snowflake',
    logoSrc: snowflakeLogo,
    docLink: 'https://habu.zendesk.com/hc/en-us/articles/9892341646093-Configuring-a-Snowflake-Pattern-Data-Connection',
    preConfig: {
      message: 'Have you installed the Snowflake CLI? This is required to proceed.',
      docLink: 'https://habu.zendesk.com/hc/en-us/articles/9892341646093-Configuring-a-Snowflake-Pattern-Data-Connection',
    },
  },
  SNOWFLAKE_DATA_CONNECTION: {
    key: 'SNOWFLAKE_DATA_CONNECTION',
    label: 'Snowflake',
    logoSrc: snowflakeLogo,
  },
  SNOWFLAKE_GCS_DATA_CONNECTION: {
    key: 'SNOWFLAKE_GCS_DATA_CONNECTION',
    label: 'Snowflake GCS Data Connection',
    logoSrc: snowflakeLogo,
    secondLogoSrc: gcsLogo,
  },
  SNOWFLAKE_S3_DATA_CONNECTION: {
    key: 'SNOWFLAKE_S3_DATA_CONNECTION',
    label: 'Snowflake S3 Data Connection',
    logoSrc: snowflakeLogo,
    secondLogoSrc: awsS3Logo,
  },
  SNOWFLAKE_AZURE_DATA_CONNECTION: {
    key: 'SNOWFLAKE_AZURE_DATA_CONNECTION',
    label: 'Snowflake Azure Data Connection',
    logoSrc: snowflakeLogo,
    secondLogoSrc: azureLogo,
  },
  SNOWFLAKE_TABLE: {
    key: 'SNOWFLAKE_TABLE',
    label: 'Snowflake',
    logoSrc: snowflakeLogo,
    docLink: 'https://habu.zendesk.com/hc/en-us/articles/9892341646093-Configuring-a-Snowflake-Pattern-Data-Connection',
  },
  TTD_REDS: {
    key: 'TTD_REDS',
    label: 'The Trade Desk',
    logoSrc: tddLogo,
  },
  ICEBERG_CATALOG: {
    key: 'ICEBERG_CATALOG',
    label: 'Iceberg Catalog',
    logoSrc: icebergLogo,
  },
  ADVERTISER_SYNTHETIC_DATASET_LIBRARY: {
    key: 'ADVERTISER_SYNTHETIC_DATASET_LIBRARY',
    label: 'Advertiser Synthetic Dataset Library',
    noConfig: {
      message: 'The Data Connection has been automatically configured. Click Save Data Connection to finish adding it.',
    },
  },
  PUBLISHER_SYNTHETIC_DATASET_LIBRARY: {
    key: 'ADVERTISER_SYNTHETIC_DATASET_LIBRARY',
    label: 'Publisher Synthetic Dataset Library',
    noConfig: {
      message: 'The Data Connection has been automatically configured. Click Save Data Connection to finish adding it.',
    },
  },
};

export const NODE_ACCESS_TYPE = {
  FULL_ACCESS: 'FULL_ACCESS',
  NO_ACCESS: 'NO_ACCESS',
};

export const PARTITION_ENABLED_DATA_CONNECTIONS = [
  'Azure Storage',
  'Client AWS S3',
  'Google Cloud Big Query',
  'Google Cloud Storage',
  'Google Cloud Storage(with SA)',
  'Habu AWS S3',
  'Habu Google Cloud Storage',
  'Snowflake Table',
  'Iceberg Catalog',
];
