import { buildNestedQueryString, buildQueryString } from 'utils/jsUtils';


const isDev = process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'test';
const utiliseGateway = (gatewayFlag) => gatewayFlag === 'true';

export const hosts = utiliseGateway(process.env.REACT_APP_USE_API_GATEWAY) ? {
  armorica: isDev ? '/armorica' : 'https://api.habu.com/internal/armorica',
  bucolix: isDev ? '/bucolix' : 'https://api.habu.com/internal/bucolix',
  forebitt: isDev ? '/forebitt' : 'https://api.habu.com/internal/forebitt',
  gomoonraker: isDev ? '/moonraker' : 'https://api.habu.com/internal/moonraker',
  harmonix: isDev ? '/harmonix' : 'https://api.habu.com/internal/harmonix',
  instantmix: isDev ? '/instantmix' : 'https://api.habu.com/internal/instantmix',
  picanmix: isDev ? '/picanmix' : 'https://api.habu.com/internal/picanmix',
  primage: isDev ? '/primage' : 'https://api.habu.com/internal/primage',
  quarterdeck: isDev ? '/quarterdeck' : 'https://api.habu.com/internal/quarterdeck',
  unhygienix: isDev ? '/unhygienix' : 'https://api.habu.com/internal/unhygienix',
} : {
  armorica: isDev ? '/armorica' : 'https://armorica.habu.com',
  bucolix: isDev ? '/bucolix' : 'https://bucolix.habu.com/bucolix',
  forebitt: isDev ? '/forebitt' : 'https://forebitt.habu.com/forebitt',
  gomoonraker: isDev ? '/moonraker' : 'https://moonraker.habu.com/moonraker',
  harmonix: isDev ? '/harmonix' : 'https://harmonix.habu.com/harmonix',
  instantmix: isDev ? '/instantmix' : 'https://instantmix.habu.com/instantmix',
  picanmix: isDev ? '/picanmix' : 'https://picanmix.habu.com/picanmix',
  primage: isDev ? '/primage' : 'https://primage.habu.com/primage',
  quarterdeck: isDev ? '/quarterdeck' : 'https://quarterdeck.habu.com/quarterdeck',
  unhygienix: isDev ? '/unhygienix' : 'https://unhygienix.habu.com/unhygienix',
};

export const auth = {
  exportToken: orgId => `${hosts.gomoonraker}/organization/${orgId}/export-token`,
  forgotPassword: `${hosts.gomoonraker}/forgot-password`,
  logout: `${hosts.gomoonraker}/auth/logout`,
  login: `${hosts.gomoonraker}/auth/login`,
  moonrakerCallBack: (code, state) => `${hosts.gomoonraker}/auth/callback${buildQueryString({ code, state })}`,
  refreshToken: `${hosts.gomoonraker}/auth/refresh_token`,
  resetPassword: `${hosts.gomoonraker}/change-password`,
  switchOrganization: orgId => `${hosts.gomoonraker}/organization/${orgId}/switch`,
  userAuthType: `${hosts.gomoonraker}/auth/authType`,
};

export const linkedinAuth = {
  getLinkedInCode: orgId => `${hosts.gomoonraker}/li/organization/${orgId}/code`,
  getLinkedInAccessToken: (code, state) => `${hosts.gomoonraker}/li/access-token?code=${code}&state=${state}`,
};

export const impersonation = {
  begin: `${hosts.gomoonraker}/impersonation/begin`,
  end: `${hosts.gomoonraker}/impersonation/end`,
};

export const demoData = {
  upload: () => `${hosts.unhygienix}/demodata/upload`,
  download: (orgId) => `${hosts.unhygienix}/demodata/organization/${orgId}/download`,
  status: (orgId) => `${hosts.unhygienix}/demodata/organization/${orgId}/details`,
};

export const helpDocs = {
  url: (token, key) => `${hosts.gomoonraker}/help-docs?token=${token}&key=${key}`,
};

export const habuPrivacyPolicy = 'https://habu.com/privacy-policy/';

export const habuHelp = {
  url: (params) => `${hosts.gomoonraker}/zendesk${buildQueryString(params)}`,
};

export const apiKey = {
  list: (orgId) => `${hosts.gomoonraker}/api-key/${orgId}/list`,
  listPartners: (orgId) => `${hosts.gomoonraker}/api-partners/${orgId}`,
  create: (orgId) => `${hosts.gomoonraker}/api-key/${orgId}/create`,
  rotate: (orgId) => `${hosts.gomoonraker}/api-key/${orgId}/rotate`,
  delete: (orgId, clientId) => `${hosts.gomoonraker}/api-key/${orgId}/${clientId}`,
};

export const gpgKey = orgId => ({
  list: `${hosts.primage}/organization/${orgId}/list-rsa-gpg`,
  generateGPGKey: `${hosts.primage}/organization/${orgId}/habu-rsa-gpg`,
  uploadGPGKey: `${hosts.primage}/organization/${orgId}/client-rsa-gpg`,
  downloadGPGKey: (secretID, name) => `${hosts.primage}/organization/${orgId}/secret/${secretID}/name/${name}/get-rsa-gpg`,
});

export const hIEmbed = {
  auth: query => `${hosts.gomoonraker}/habuintelligence/auth${buildQueryString(query)}`,
};

export const cleanRoom = orgId => ({
  addCrUser: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/user`,
  addCrUsers: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/users`,
  addQuestion: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/add-question`,
  addOrgCRQuestions: `${hosts.unhygienix}/organization/${orgId}/questions/add`,
  allStorageProviders: `${hosts.unhygienix}/storage-providers`,
  alert: (crId, alertId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts/${alertId}`,
  canonicalFields: dataType => `${hosts.unhygienix}/data-type/${dataType}/canonical-fields`,
  checkAccess: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/checkAccess`,
  cleanRoomTypes: () => `${hosts.unhygienix}/clean-room-types?organizationID=${orgId}`,
  cleanRoomTypesByCleanRoomID: (cleanRoomID) => `${hosts.unhygienix}/clean-room-types?organizationID=${orgId}&cleanRoomID=${cleanRoomID}`,
  clone: (accountId, crId) => `${hosts.unhygienix}/account/${accountId}/organization/${orgId}/clean-room/${crId}/clone`,
  cloneQuestion: (crId, crQuestionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/clone`,
  create: `${hosts.unhygienix}/organization/${orgId}/clean-room`,
  createAlert: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts`,
  createDataset: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/dataset`,
  createJoinedDataset: '/api/joined-dataset/create-draft',
  createNewRun: (crId, crQuestionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run`,
  createQuestion: `${hosts.unhygienix}/question`,
  createScheduledRun: () => `${hosts.unhygienix}/cleanroom/schedule`,
  crQuestionPermissions: (crId, crQuestionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/permissions`,
  deleteScheduledRun: (crId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/id/${runId}/deleteSchedule`,
  getScheduledRun: (crId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/id/${runId}/findSchedule`,
  getScheduledRuns: (crId, qId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/question/${qId}/findSchedules`,
  updateScheduledRunStatus: (crId, runId, status) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/id/${runId}/status/${status}/updateStatus`,
  crDetail: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}`,
  details: crId => `${hosts.unhygienix}/clean-room/${crId}?organizationID=${orgId}`,
  runReportCsv: (crId, crQId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQId}/run/${runId}/export`,
  downloadOutputFile: (crId, crQuestionId, runId, fileName) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}/download/${fileName}`,
  downloadRunOutputFile: (crId, crQuestionId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}/download-output`,
  questionDetails: questionId => `${hosts.unhygienix}/question/${questionId}`,
  getAlertsNotificationsFilters: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts/notifications/filters`,
  getAlertNotifications: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts/notifications`,
  getAlertResults: (crId, alertId, timestamp) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts/${alertId}/results/${timestamp}`,
  getDataset: (cleanRoomId, datasetId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/dataset/${datasetId}`,
  getAllDatasets: (cleanRoomId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/dataset/list`,
  getDatasetTransFunc: `${hosts.unhygienix}/dataset-transformation-functions`,
  getOutputFiles: (crId, crQuestionId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}/files`,
  getCrRunDetail: (crId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question-run/${runId}/get-post-run-details`,
  getFilePreview: (crId, crQuestionId, runId, name) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}/file/${name}`,
  getHISchemaInfo: (crId, crQuestionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/hi-schema-info`,
  getHISchemaInfoList: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/hi-schema-info`,
  getRunReport: (crId, crQuestionId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}/report`,
  getRunTableReport: (crId, crQuestionId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}/table`,
  getQuestionRun: (crId, crQuestionId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}`,
  getPrivacyBudgetAndQueries: (partnerTrustLevel, partnerRiskAppetite) => `${hosts.unhygienix}/privacy/organization/${orgId}/trust-level/${partnerTrustLevel}/risk-appetite/${partnerRiskAppetite}`,
  deleteQuestion: (crId, crQuestionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/delete`,
  deleteQuestionRun: (crId, crQuestionId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}/delete`,
  getRuns: (crId, crQuestionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/list`,
  fetchRunsFilters: (crId, crQuestionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room-question/${crQuestionId}/filters`,
  getQuestion: questionId => `${hosts.unhygienix}/question/${questionId}`,
  getCleanRoomQuestion: (crId, questionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/question/${questionId}`,
  getCleanRoomQuestionV2: (crId, cleanRoomQuestionID) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${cleanRoomQuestionID}`,
  getCleanRoomQuestionPermissions: (crId, cleanRoomQuestionID) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${cleanRoomQuestionID}/permissions`,
  getCleanRoomJoinedDatasets: datasetId => `/api/joined-dataset/${datasetId}`,
  list: `${hosts.unhygienix}/organization/${orgId}/clean-room/list`,
  listAlerts: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts`,
  listAlertsFilters: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts/list/filters`,
  listAlertsWithFilter: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts-with-filters`,
  listAllQuestions: `${hosts.unhygienix}/question/list`,
  availableQuestionFilters: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/available-question/filters`,
  questionFilters: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/question/filters`,
  questionList: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/question/list`,
  listAvailableQuestions: crId => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${crId}/available-questions`,
  listAvailableQuestionsFlow: crId => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${crId}/flows/available-questions`,
  listCurrentQuestions: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/question/list`,
  listDatasetGroup: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/import-jobs-grouped`,
  listDatasetGroupAll: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/import-all-jobs-grouped`,
  listExportableCleanRooms: `${hosts.unhygienix}/organization/${orgId}/clean-room/exportable`,
  listFilters: `${hosts.unhygienix}/organization/${orgId}/clean-room/filters`,
  orgCRQuestions: `${hosts.unhygienix}/organization/${orgId}/questions/list`,
  questionDataOptions: (crId, crQId, isPartnerAssignedDatasets) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQId}/data-type-options?isPartnerAssignedDatasets=${isPartnerAssignedDatasets}`,
  questionDatasets: (crId, qId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${qId}/datasets`,
  removeUser: (crId, userId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/user/${userId}`,
  reportFilters: (crId, crQuestionId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/run/${runId}/report/filters`,
  storageProviders: `${hosts.unhygienix}/organization/${orgId}/storage-providers`,
  summary: `${hosts.unhygienix}/organization/${orgId}/clean-room/summary`,
  listCrOrgUsers: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/organization-users`,
  listCrStakeholders: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/stakeholders`,
  updateAlertStatus: (crId, alertId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/alerts/${alertId}/change-status`,
  updateCleanRoomTypes: `${hosts.unhygienix}/organization/${orgId}/clean-room-types`,
  updateCrQuestionStatus: (crId, crQId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQId}/change-status`,
  updateCrStatus: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/change-status`,
  updateCrUser: (crId, userId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/user/${userId}`,
  updateDataset: (crId, datasetId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/dataset/${datasetId}`,
  updateDatasetDelegation: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/dataset-delegation`,
  updateQuestion: questionId => `${hosts.unhygienix}/question/${questionId}`,
  updateCrQuestionDetails: (crId, questionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/question/${questionId}/update-detail`,
  updateCrQuestionDetailsV2: (crId, cleanRoomQuestionID) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${cleanRoomQuestionID}/update-detail`,
  users: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/user/list`,
  userOrgName: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/userOrgName`,
  habuIntelligenceAuthHandler: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/habu-intelligence-auth-handler`,
  triggerHabuIntelligenceRun: (crId, crQuestionId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQuestionId}/trigger-hi-run`,
  hiDashboards: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/hi/dashboards`,
  hiBookmarks: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/hi/dashboards/bookmark/true`,
  hiSaveBookmarks: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/hi/dashboards/bookmarks`,
  provisionCleanRoom: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/provision`,
  duplicateQuestion: (crId, crQId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQId}/duplicate`,
  hiProvisionedCRQs: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/question/list-hi-provisioned`,
  cleanRoomCliVersion: `${hosts.primage}/cli/version/${orgId}`,
  cliVersionUpdate: crType => `${hosts.primage}/cli/version/${orgId}/${crType}`,
  cloneToTargetCleanRooms: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clone-to-target-crs`,
  cloneableQuestionsIdList: (templateCrId) => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${templateCrId}/cloneable-question-ids`,
  clearErrorMessage: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clear-error-message`,
  cloneableQuestionsList: (templateCrId, targetCrId) => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${templateCrId}/cloneable-questions/${targetCrId}`,
  cloneableQuestionsFilter: (templateCrId) => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${templateCrId}/cloneable-questions/filters`,
  cloneableFlowsList: (templateCrId, targetCrId) => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${templateCrId}/cloneable-flows/${targetCrId}`,
  cloneableFlowsFilter: (templateCrId) => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${templateCrId}/cloneable-flows/filters`,
  provisionTemplateToTargetOrgs: (templateCrId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${templateCrId}/provision-template-to-target-orgs`,
  getQuestionRunResultShare: (crId, runID) => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${crId}/run/${runID}/result-share`,
  privacyBudgetOverride: (crId, cleanRoomFlowID) => `${hosts.unhygienix}/privacy/organization/${orgId}/clean-room/${crId}/entity/${cleanRoomFlowID}/privacy-budget-override`,
  budgetAccountantView: crId => `${hosts.unhygienix}/privacy/organization/${orgId}/clean-room/${crId}/budget-audit`,
  privacyBudgetRefresh: crId => `${hosts.unhygienix}/privacy/organization/${orgId}/clean-room/${crId}/admin-budget-refresh`,
  addQuestionBulk: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/add-question-list`,
  exportJobsList: crId => `${hosts.picanmix}/organization/${orgId}/clean-room/${crId}/export-job/list`,
  getCleanRoomQuestionCountByDatasetID: (crId, datasetId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/dataset/${datasetId}/clean-room-question/count`,
  deprovisionDataset: (crId, datasetId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/dataset/${datasetId}/unconfigure`,
  crDefaultQuestionPermissions: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/default-question-permissions`,
  taxonomy: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/taxonomy`,
  crQuestionConfigParameter: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question-config-parameter`,
  crQuestionConfigParameters: (crId, crqId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question-config-parameters/${crqId}`,
  createOrUpdateConfigParameters: (crId, crqId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crqId}/parameters`,
  deleteCrqConfigParameter: (crId, crqId, paramName) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/question/${crqId}/config-parameter/${paramName}`,
  crFederatedQuery: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/federated-query`,
  allCRQRuns: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question-runs`,
  allCRQRunsFilters: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question-runs/filters`,
  crAssetMapping: (crId) => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${crId}/mapping`,
  crAsset: (crId) => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${crId}/asset`,
  crqAsset: (crId, crQId) => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${crId}/question/${crQId}/asset`,
  searchPartners: (searchParam) => `${hosts.picanmix}/partner/list?search=${searchParam}`,
  createPartnerAccount: crId => `${hosts.picanmix}/organization/${orgId}/clean-room/${crId}/partner-account`,
  getLinkedDatasetsWithVersion: (questionId, crId) => `${hosts.unhygienix}/v2/question/${questionId}/cleanRoom/${crId}/linked-datasets`,
  upsertCrParameters: (crId) => `${hosts.unhygienix}/v1/organization/${orgId}/clean-room/${crId}/parameters`,
  upsertCleanRoom: `${hosts.unhygienix}/v1/organization/${orgId}/clean-room`,
  deleteCrParameter: (crId, paramName) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/parameter/${paramName}`,
});

export const crRunReport = orgId => ({
  questionRuns: (crId, questionId, filter) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${questionId}/run/list-info${buildQueryString(filter)}`,
  resolutionDataConnectionVersion: (crId, crQId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crQId}/run/${runId}/resolution-dataconnection-versions`,
});

export const crUserList = (orgId, crId) => ({
  datasetByIdentities: `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/datasets-grouped-by-identifiers`,
  details: ulId => `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/userlist/${ulId}`,
  delete: ulId => `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/userlist/${ulId}`,
  filters: `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/userlist/filters`,
  list: `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/userlist/list`,
  updateStatus: ulId => `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/userlist/${ulId}/status`,
  saveUserList: `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/userlist`,
});

export const crPartners = (orgId, crId) => ({
  invitePartner: `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/trigger-partner-invite`,
  sendConfirmEmailToPartnerAdmin: (invitationId, acId) => `${hosts.unhygienix}/account/${acId}/organization/${orgId}/invitation/${invitationId}/alert-partner-admin`,
  invitePartnerV2: `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/invite-partner-v2`,
  add: `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/partner`,
  acceptInvite: invitationId => `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/partner/accept-invite/${invitationId}`,
  acceptInviteV2: (invitationId, acId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/account/${acId}/partner/accept-invite-v2/${invitationId}`,
  rejectInvite: invitationId => `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/partner/decline-invite/${invitationId}`,
  rejectInviteV2: (invitationId, acId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/account/${acId}/partner/decline-invite-v2/${invitationId}`,
  fetchInvitations: `${hosts.armorica}/v2/organization/${orgId}/invitation-list`,
  changeInvitationDetailStatus: invitationId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/invite-detail/${invitationId}/change-status`,
  fetchPartnerInvitations: `${hosts.armorica}/v2/organization/partner/${orgId}/invitation-list`,
  fetchPartnerInvitationsByUser: `${hosts.unhygienix}/organization/${orgId}/invitation-list-user`,
  list: `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/partner/list`,
  invitationList: `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/invitation-list`,
  invitationListByAccount: acId => `${hosts.unhygienix}/account/${acId}/invitation-list-account`,
  linkPartner: `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/invite-partner`,
  detail: id => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/partner/${id}`,
  partners: `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/organizations-list`,
  shareablePartners: `${hosts.armorica}/v2/organization/${orgId}/clean-room/${crId}/partners`,
  partnerUsers: partnerId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/partner/${partnerId}/partner-user/list`,
  partnerPermissions: partnerId => `${hosts.unhygienix}/organization/${partnerId}/clean-room/${crId}/cleanroom-permissions`,
  setPartnerPermissions: partnerId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/partner/${partnerId}/partner-permissions`,
  crPermissions: `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/cleanroom-permissions`,
  role: (partnerId, roleId) => `${hosts.unhygienix}/organization/${partnerId}/cleanroom/${crId}/cleanroom-role/${roleId}`,
  roles: partnerId => `${hosts.unhygienix}/organization/${partnerId}/cleanroom/${crId}/cleanroom-roles`,
  createRole: partnerId => `${hosts.unhygienix}/organization/${partnerId}/cleanroom/${crId}/cleanroom-role`,
  updateRole: (partnerId, roleId) => `${hosts.unhygienix}/organization/${partnerId}/cleanroom/${crId}/cleanroom-role/${roleId}`,
  updateUserRole: (partnerId, userId, roleId) => `${hosts.unhygienix}/organization/${partnerId}/clean-room/${crId}/user/${userId}/role/${roleId}`,
  deleteRole: (partnerId, roleId) => `${hosts.unhygienix}/organization/${partnerId}/cleanroom/${crId}/cleanroom-role/${roleId}`,
  deleteUser: (partnerId, userId) => `${hosts.unhygienix}/organization/${partnerId}/clean-room/${crId}/user/${userId}`,
  verifyPartnerEmail: `${hosts.gomoonraker}/organizations/${orgId}/verify-partner-email`,
  verifyPartnerKey: `${hosts.gomoonraker}/organizations/${orgId}/verify-partner-api-key`,
  savePartnerCredential: (credentialId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/partner-credential/${credentialId}`,
});

export const crTemplate = (orgId) => ({
  createTemplate: `${hosts.unhygienix}/organization/${orgId}/clean-room-template`,
  listTemplates: `${hosts.unhygienix}/organization/${orgId}/clean-room-template/list`,
  listTemplateFilters: `${hosts.unhygienix}/organization/${orgId}/clean-room-template/filters`,
  updateCrTemplate: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${crId}`,
  deleteCrTemplate: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${crId}`,
  acceptCRContract: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${crId}/contract/accept`,
  uploadCrContract: `${hosts.unhygienix}/clean-room-template/upload-contract`,
  getCrContract: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${crId}/contract`,
  downloadCrContract: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room-template/${crId}/contract/download`,
});

export const dataCredential = orgId => ({
  createCredentialSource: `${hosts.primage}/credential/source`,
  orgCredential: `${hosts.primage}/credential/organization`,
  createOrgCredSource: `${hosts.primage}/credential/organization/${orgId}`,
  delete: credSourceId => `${hosts.primage}/credential/source/${credSourceId}`,
  deleteOrgCredSource: credSourceId => `${hosts.primage}/credential/organization/${orgId}/${credSourceId}`,
  getCredentialSource: credSourceId => `${hosts.primage}/credential/source/${credSourceId}`,
  getOrgCredential: credSourceId => `${hosts.primage}/credential/organization/${orgId}/${credSourceId}`,
  generateSecret: credSourceId => `${hosts.primage}/credential/activate/${orgId}/${credSourceId}`,
  listCredentialSources: `${hosts.primage}/credential/source/list?excludeManagedCredential=true&excludeInternalCredential=true`,
  listOrgCredentials: filter => `${hosts.primage}/credential/organization/list/${orgId}${buildQueryString(filter)}`,
  updateCredentialSource: credSourceId => `${hosts.primage}/credential/source/${credSourceId}`,
  updateOrgCredSource: credSourceId => `${hosts.primage}/credential/organization/${orgId}/${credSourceId}`,
});

export const dataIn = orgId => ({
  attributeCategoryList: `${hosts.forebitt}/attribute-category/list`,
  attributeValuesByName: (name, dataInJobId) => `${hosts.forebitt}/organization/${orgId}/attribute-name/value/list?attributeName=${name}&dataImportJobID=${dataInJobId}`,
  attributeList: o => `${hosts.forebitt}/organization/${orgId}/attribute/list${buildQueryString(o)}`,
  attributeValueList: attrId => `${hosts.forebitt}/organization/${orgId}/attribute/${attrId}/value/list`,
  changeStatus: id => `${hosts.forebitt}/v2/organization/${orgId}/import-job/${id}/change-status`,
  createJob: `${hosts.forebitt}/organization/${orgId}/import-job`,
  createJobConsent: id => `${hosts.forebitt}/organization/${orgId}/jobs/${id}/consent`,
  dataInJobDetails: id => `${hosts.forebitt}/organization/${orgId}/import-job/${id}`,
  dataInJobOverview: id => `${hosts.forebitt}/v2/organization/${orgId}/import-job/${id}`,
  dataInJobCsv: (jobId, filter) => `${hosts.forebitt}/organization/${orgId}/import-job/${jobId}/job-run/export${buildQueryString(filter)}`,
  dataSource: id => `${hosts.forebitt}/import-data-source/${id}`,
  dataSourceJobs: `${hosts.forebitt}/v2/organization/${orgId}/import-job-by-source`,
  dataSourceParameters: (dataSourceId, importDataTypeID, isCDP) => `${hosts.forebitt}/import-data-source/${dataSourceId}/parameters${buildQueryString({ organizationID: orgId, importDataTypeID })}&rootParameters=true&isCDP=${isCDP}`,
  dataSourceDependentParameters: (dataSourceId, parameterId, paramValue) => `${hosts.forebitt}/organization/${orgId}/import-data-source/${dataSourceId}/parameter/${parameterId}/dependent-parameters${buildQueryString(paramValue)}`,
  deviceTypeList: `${hosts.forebitt}/device-type/list`,
  downloadCSV: `${hosts.forebitt}/organization/${orgId}/import-job-local/files/download`,
  fieldConfigList: (id, includedOnly = false) => `${hosts.forebitt}/organization/${orgId}/jobs/${id}/field-configuration/list${includedOnly ? '/excluded/false' : ''}`,
  fieldDelimiter: `${hosts.forebitt}/field-delimiter/list`,
  fieldMapping: (jobID) => `${hosts.unhygienix}/organization/${orgId}/dataset/jobID/${jobID}/clean-room-datasets`,
  filters: `${hosts.forebitt}/v2/organization/${orgId}/filters`,
  identifierTypeList: `${hosts.forebitt}/organization/${orgId}/identifier-type/list`,
  importDataTypeList: dataSourceID => `${hosts.forebitt}/import-data-type/list${buildQueryString({ organizationID: orgId, dataSourceID })}`,
  jobConsentList: id => `${hosts.forebitt}/organization/${orgId}/jobs/${id}/consent`,
  jobTrend: (id, startDate, domain) => `${hosts.forebitt}/v2/organization/${orgId}/import-job/${id}/trend${buildQueryString({ startDate, domain })}`,
  jobsSummary: `${hosts.forebitt}/organization/${orgId}/import-job/summary`,
  jobStats: importJobRunId => `${hosts.forebitt}/organization/${orgId}/import-job/${importJobRunId}/job-runs`,
  jobList: `${hosts.forebitt}/v2/organization/${orgId}/import-job/list`,
  jobParameters: (dataSourceId, jobId, paramName) => `${hosts.forebitt}/organization-job-parameters/${orgId}/${dataSourceId}/${jobId}${buildQueryString({ name: paramName })}`,
  listOrgDataSources: `${hosts.forebitt}/organization/${orgId}/import-data-source/list`,
  listAllDataSources: `${hosts.forebitt}/import-data-source/list`,
  peopleHouseholdTrend: (id, startDate, domain) => `${hosts.forebitt}/v2/organization/${orgId}/import-job/${id}/people-hh-trend${buildQueryString({ startDate, domain })}`,
  retryImportJob: id => `${hosts.forebitt}/v2/organization/${orgId}/import-job/${id}/retry`,
  snowflakePatternFields: id => `${hosts.forebitt}/v2/organization/${orgId}/snowflake-data-connection/${id}/fields`,
  submitFieldConfigurations: id => `${hosts.forebitt}/organization/${orgId}/jobs/${id}/field-configuration/bulk`,
  upload: `${hosts.forebitt}/import-job-local/upload`,
  listUploadedFiles: (s3Location) => `${hosts.forebitt}/organization/${orgId}/import-job-local/files${buildQueryString({ s3Location })}`,
  deleteUploadedFile: (s3Location, fileName) => `${hosts.forebitt}/organization/${orgId}/import-job-local/delete${buildQueryString({ s3Location, fileName })}`,
  deleteOldFile: (jobId) => `${hosts.forebitt}/organization/${orgId}/import-job-local/job/${jobId}/delete`,
  updateJob: jobId => `${hosts.forebitt}/organization/${orgId}/import-job/${jobId}`,
  updateJobParameters: (dataSourceId, jobId) => `${hosts.forebitt}/organization-job-parameters/${orgId}/${dataSourceId}/${jobId}`,
  updateJobStatus: id => `${hosts.forebitt}/organization/${orgId}/import-job/${id}/status`,
  changeJobStage: id => `${hosts.forebitt}/v2/organization/${orgId}/import-job/${id}/change-stage`,
  updateOrgImportSources: `${hosts.forebitt}/organization/${orgId}/import-data-source/add`,
  updateOrgImportDataTypes: `${hosts.forebitt}/organization/${orgId}/import-data-type/add`,
  useCases: `${hosts.forebitt}/organization/${orgId}/datasource-usecases/list`,
  deleteDataConnection: id => `${hosts.forebitt}/organization/${orgId}/import-job/${id}`,
  fetchAssociatedCRQCount: jobId => `${hosts.unhygienix}/organization/${orgId}/dataset/jobID/${jobId}/clean-room-question-datasets`,
  listCrqDatasetParameters: (crId, crqId, datasetId, paramName) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crqId}/dataset/${datasetId}/parameters${buildQueryString({ parameterName: paramName })}`,
  fetchJobPartitionFields: id => `${hosts.forebitt}/organization/${orgId}/jobs/${id}/partition-fields`,
  resolutionActivity: runId => `${hosts.forebitt}/organization/${orgId}/import-job/${runId}/resolution-activity`,
});

export const dataConnections = orgId => ({
  get: (id) => `${hosts.forebitt}/organization/${orgId}/import-job/${id}/params?rootParameters=true`,
  create: `${hosts.forebitt}/organization/${orgId}/import-job-params`,
  createSynthetic: `${hosts.forebitt}/organization/${orgId}/synthetic-import-job`,
  update: id => `${hosts.forebitt}/organization/${orgId}/import-job/${id}/params`,
  dataSourceParameters: (dataSourceId, importDataTypeID, isCDP) => `${hosts.forebitt}/organization/${orgId}/import-data-source/${dataSourceId}/parameters?importDataTypeID=${importDataTypeID}&rootParameters=true&isCDP=${isCDP}`,
});

export const dataSourceLocation = (orgId = '') => ({
  create: `${hosts.primage}/data-source-location/organization`,
  get: `${hosts.primage}/data-source-location/organization/${orgId}`,
  list: `${hosts.primage}/data-source-location/organization/${orgId}/list`,
});

export const dataOut = orgId => ({
  activation: jobId => `${hosts.picanmix}/organization/${orgId}/job/${jobId}`,
  activations: `${hosts.picanmix}/organization/${orgId}/job/list`,
  activationFilters: jobType => `${hosts.picanmix}/organization/${orgId}/job/filters?jobType=${jobType}`,
  activationNames: filter => `${hosts.picanmix}/organization/${orgId}/job-details/filters${buildQueryString(filter)}`,
  activationReport: (jobId, filter) => `${hosts.picanmix}/organization/${orgId}/job/${jobId}/job-run/list${buildQueryString(filter)}`,
  activationReportCsv: jobId => `${hosts.picanmix}/organization/${orgId}/job/${jobId}/job-run/export`,
  activateUserList: partnerId => `${hosts.picanmix}/organization/${orgId}/partner-account/${partnerId}/job`,
  activateOfflineConversion: partnerId => `${hosts.picanmix}/organization/${orgId}/offline-conversion/${partnerId}/jobs`,
  activatePartnerAccounts: userListId => `${hosts.picanmix}/organization/${orgId}/user-list/${userListId}/job`,
  activateQuestion: `${hosts.picanmix}/question/activate`,
  activateQuestionRun: `${hosts.picanmix}/question_run/job/activate`,
  cleanRoomProvisionChannelList: `${hosts.picanmix}/cleanroom/partner-provisions`,
  createExport: `${hosts.picanmix}/organization/${orgId}/export/job`,
  createPartnerAccount: `${hosts.picanmix}/organization/${orgId}/partner-account`,
  dataOutSummary: `${hosts.picanmix}/organization/${orgId}/summary`,
  deleteActivation: jobId => `${hosts.picanmix}/organization/${orgId}/job/${jobId}`,
  deleteActivationRun: (jobId, runId) => `${hosts.picanmix}/organization/${orgId}/job/${jobId}/job-run/${runId}`,
  partnerAccount: partnerAccountID => `${hosts.picanmix}/organization/${orgId}/partner-account/${partnerAccountID}`,
  jobsVerificationStatus: partnerAccountID => `${hosts.picanmix}/organization/${orgId}/partner-account/${partnerAccountID}/job-verification`,
  jobPartnersVerification: sourceID => `${hosts.picanmix}/organization/${orgId}/source-id/${sourceID}/job-verification`,
  partnerAccounts: `${hosts.picanmix}/organization/${orgId}/partner-account/list`,
  partnerAccountFilters: `${hosts.picanmix}/organization/${orgId}/partner-account/filters`,
  partners: `${hosts.picanmix}/partner/list`,
  provisionCRChannels: `${hosts.picanmix}/cleanroom/partner/provision`,
  deprovisionCRChannels: `${hosts.picanmix}/cleanroom/partner/deprovision`,
  questionActivatedChannels: questionID => `${hosts.picanmix}/organization/${orgId}/job/question/${questionID}`,
  rerunActivationJob: `${hosts.picanmix}/question_run/job/activate/rerun`,
  updateActivationStatus: jobId => `${hosts.picanmix}/organization/${orgId}/job/${jobId}/change-status`,
  activationsPartnerList: partnerId => `${hosts.picanmix}/organization/${orgId}/partner-account/${partnerId}/job/list`,
  exportJobsList: `${hosts.picanmix}/organization/${orgId}/export-job/list`,
  exportJobRuns: jobId => `${hosts.picanmix}/organization/${orgId}/export-job/${jobId}/runs`,
  deleteExportJob: `${hosts.picanmix}/organization/${orgId}/job`,
});

export const activationChannels = orgId => ({
  platformPartners: `${hosts.picanmix}/partner/list`,
  platformPartner: id => `${hosts.picanmix}/partner/${id}`,
  orgPartners: searchQuery => `${hosts.picanmix}/partner/list/${orgId}/search/${searchQuery}`,
  orgPartnerAccounts: `${hosts.picanmix}/organization/${orgId}/partner-account/list`,
  createChannel: `${hosts.picanmix}/organization/${orgId}/partner-account`,
  updateChannel: id => `${hosts.picanmix}/organization/${orgId}/partner-account/${id}`,
  createCustomActivationChannel: `${hosts.picanmix}/partner/request/${orgId}`,
  deletePartnerAccount: id => `${hosts.picanmix}/organization/${orgId}/partner-account/${id}`,
  partnerAccountList: crId => `${hosts.picanmix}/organization/${orgId}/clean-room/${crId}/activation-channels/list`,
  provisionedPartnerAccountsCount: partnerAccountID => `${hosts.picanmix}/organization/${orgId}/partner-account/${partnerAccountID}/partner-provisions`,
  crPartnerActivationsCount: (cleanRoomID, partnerAccountID) => `${hosts.picanmix}/organization/${orgId}/clean-room/${cleanRoomID}/partner-account/${partnerAccountID}/activations`,
  activationJobList: sourceID => `${hosts.picanmix}/organization/${orgId}/activation/${sourceID}/job/list`,
});

export const experiment = orgId => ({
  audiences: `${hosts.bucolix}/organization/${orgId}/harmonix/user-lists`,
  details: id => `${hosts.harmonix}/organization/${orgId}/experiment/${id}`,
  endExp: id => `${hosts.harmonix}/organization/${orgId}/experiment/${id}/close`,
  experimentFilters: `${hosts.harmonix}/organization/${orgId}/filters`,
  list: `${hosts.harmonix}/organization/${orgId}/experiment/list`,
  partnerConnections: `${hosts.harmonix}/organization/${orgId}/partner-connections`,
  save: id => `${hosts.harmonix}/organization/${orgId}/experiment${id}`,
  summary: `${hosts.harmonix}/organization/${orgId}/experiments/summary`,
  updateExperimentStatus: jobId => `${hosts.harmonix}/organization/${orgId}/experiment/${jobId}/change-status`,
});

export const forefootScripts = {
  creativeHelper: 'https://shelter.imhd.io/declared/cringle.js',
};

export const fulcrum = {
  logs: 'https://fulcrum-antenna.imhd.io/collect/jserrors/f3e78c08-0dd5-4a86-a775-6e803d6cce69/imhd ',
};

export const fullViewAutomation = orgId => ({
  changeFvaQuestionStatus: (fvaId, fvaQuestionId) => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}/question/${fvaQuestionId}/change-status`,
  changeFvaStatus: fvaId => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}/change-status`,
  create: `${hosts.instantmix}/organization/${orgId}/full-view-automation`,
  createQuestion: `${hosts.instantmix}/question`,
  createReport: (fvaId, questionId) => `${hosts.instantmix}/fva/${fvaId}/question/${questionId}/run`,
  fvaDetails: fvaId => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}`,
  fvaFilters: `${hosts.instantmix}/organization/${orgId}/full-view-automation/filters`,
  fvaQuestionsFilters: fvaId => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}/question/filters`,
  fvaReportCsv: (fvaId, questionId, runId, filter) => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}/question/${questionId}/fva-question-run/${runId}/report/export${buildNestedQueryString(filter)}`,
  getQuestion: questionId => `${hosts.instantmix}/question/${questionId}`,
  listFva: `${hosts.instantmix}/organization/${orgId}/full-view-automation/list`,
  listFvaQuestionRun: (fvaId, questionId) => `${hosts.instantmix}/fva/${fvaId}/question/${questionId}/run/list`,
  listFvaQuestions: fvaId => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}/question/list`,
  orgEnableFvaQuestions: `${hosts.instantmix}/organization/${orgId}/enable-questions`,
  orgFvaQuestions: `${hosts.instantmix}/organization/${orgId}/questions/list`,
  orgFvaQuestionsCount: `${hosts.instantmix}/organization-question/count`,
  partnerList: `${hosts.instantmix}/partner/list`,
  questionDetails: questionId => `${hosts.instantmix}/question/${questionId}`,
  report: (fvaId, questionId) => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}/question/${questionId}/report`,
  reportFilters: (fvaId, questionId) => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}/question/${questionId}/filters`,
  reportFrequency: (fvaId, questionId) => `${hosts.instantmix}/organization/${orgId}/full-view-automation/${fvaId}/question/${questionId}/frequency`,
  summary: `${hosts.instantmix}/organization/${orgId}/summary`,
  updateQuestion: questionId => `${hosts.instantmix}/question/${questionId}`,
});

export const iamAccounts = {
  accountAdminList: accountId => `${hosts.gomoonraker}/account/${accountId}/account-administrator/list`,
  removeAccAdmin: (accountId, userId) => `${hosts.gomoonraker}/account/${accountId}/user/${userId}`,
  create: `${hosts.gomoonraker}/accounts`,
  createAdministrator: accountId => `${hosts.gomoonraker}/account/${accountId}/add-account-administrator`,
  get: id => `${hosts.gomoonraker}/account/${id}`,
  list: `${hosts.gomoonraker}/accounts`,
  listDemo: `${hosts.gomoonraker}/demo-accounts`,
  update: id => `${hosts.gomoonraker}/account/${id}`,
  userRelatedOrgList: orgId => `${hosts.gomoonraker}/organizations/${orgId}/organizations`,
  userRelatedOrgFilter: orgId => `${hosts.gomoonraker}/organizations/${orgId}/all_user_organizations_filters`,
};

export const iamOrganizations = accountId => ({
  createOrganization: `${hosts.gomoonraker}/accounts/${accountId}/organization`,
  fetchAdmin: userId => `${hosts.gomoonraker}/account/${accountId}/account-administrator/${userId}`,
  get: id => `${hosts.gomoonraker}/accounts/${accountId}/organization/${id}`,
  listOrganizations: `${hosts.gomoonraker}/accounts/${accountId}/user-organizations`,
  update: id => `${hosts.gomoonraker}/accounts/${accountId}/organization/${id}`,
  delete: orgId => `${hosts.gomoonraker}/organization/${orgId}`,
  getCleanRoomTypes: `${hosts.unhygienix}/account/${accountId}/clean-room-types`,
});

export const iamRoles = orgId => ({
  create: `${hosts.gomoonraker}/organizations/${orgId}/roles`,
  get: `${hosts.gomoonraker}/organizations/${orgId}/role`,
  list: `${hosts.gomoonraker}/organizations/${orgId}/roles`,
  update: `${hosts.gomoonraker}/organizations/${orgId}/role`,
  delete: roleId => `${hosts.gomoonraker}/organizations/${orgId}/role/${roleId}`,
});

export const iamUsers = orgUid => ({
  create: `${hosts.gomoonraker}/organizations/${orgUid}/users`,
  get: `${hosts.gomoonraker}/organizations/${orgUid}/user`,
  list: `${hosts.gomoonraker}/organizations/${orgUid}/users_list`,
  update: userId => `${hosts.gomoonraker}/organizations/${orgUid}/user/${userId}`,
  updateAdmin: (accountID, userId) => `${hosts.gomoonraker}/account/${accountID}/account-administrator/${userId}`,
  userFilters: `${hosts.gomoonraker}/organizations/${orgUid}/user_filters`,
  deleteUser: userId => `${hosts.gomoonraker}/organization/${orgUid}/user/${userId}`,
  deleteOrgUser: userId => `${hosts.gomoonraker}/organization/${orgUid}/organization-user/${userId}`,
  welcomeEmail: `${hosts.gomoonraker}/organizations/${orgUid}/send-welcome-email`,
  accountWelcomeEmail: accountId => `${hosts.gomoonraker}/account/${accountId}/send-welcome-email`,
});

export const iamProduct = {
  accountProducts: accountID => `${hosts.gomoonraker}/account/${accountID}/products`,
  productList: `${hosts.gomoonraker}/products`,
  updateAccProducts: accountID => `${hosts.gomoonraker}/account/${accountID}/products`,
  organizationProducts: orgID => `${hosts.gomoonraker}/organization/${orgID}/product-permissions`,
  updateOrgProducts: orgID => `${hosts.gomoonraker}/organization/${orgID}/products`,
};

export const lookups = orgUid => ({
  domains: `${hosts.gomoonraker}/organizations/${orgUid}/properties`,
  propertyTypes: `${hosts.gomoonraker}/lookups/propertyTypes`,
});

export const properties = orgId => ({
  archive: `${hosts.gomoonraker}/organizations/${orgId}/properties/archive`,
  archived: `${hosts.gomoonraker}/organizations/${orgId}/archived-properties`,
  configurations: `${hosts.quarterdeck}/${orgId}/config`,
  create: `${hosts.gomoonraker}/organizations/${orgId}/properties`,
  createQuarterdeckConfig: propertyId => `${hosts.quarterdeck}/${orgId}/${propertyId}/config`,
  details: propertyId => `${hosts.gomoonraker}/organizations/${orgId}/property/${propertyId}`,
  fetchForebittConfig: propertyId => `${hosts.forebitt}/organization/${orgId}/habu-js-import-job/${propertyId}`,
  list: `${hosts.gomoonraker}/organizations/${orgId}/properties`,
  restore: `${hosts.gomoonraker}/organizations/${orgId}/properties/restore-archived`,
  update: propertyId => `${hosts.gomoonraker}/organizations/${orgId}/property/${propertyId}`,
  updateForebittConfig: `${hosts.forebitt}/organization/${orgId}/habu-js-import-job`,
  updateQuarterdeckConfig: (propertyId, id) => `${hosts.quarterdeck}/${orgId}/${propertyId}/config/${id}`,
});

export const question = (orgId) => ({
  activation: {
    create: `${hosts.picanmix}/question_activation/create`,
    delete: `${hosts.picanmix}/question_activation/delete`,
    list: (cleanRoomId, cleanRoomQuestionId) => `${hosts.picanmix}/organization/${orgId}/cleanroom/${cleanRoomId}/cleanroom-question/${cleanRoomQuestionId}/activation-list`,
    options: (cleanRoomId, cleanRoomQuestionId) => `${hosts.picanmix}/organization/${orgId}/cleanroom/${cleanRoomId}/cleanroom-question/${cleanRoomQuestionId}/activation-options`,
  },
  create: `${hosts.unhygienix}/v2/question`,
  datasets: cleanRoomId => `${hosts.unhygienix}/v2/question/organization/${orgId}/clean-room/${cleanRoomId}/datasets`,
  detail: questionId => `${hosts.unhygienix}/v2/question/${questionId}`,
  publish: questionId => `${hosts.unhygienix}/question/${questionId}/publish`,
  validate: `${hosts.unhygienix}/v2/question/validate`,
  verify: `${hosts.unhygienix}/v2/question/verify`,
  queryLanguages: storageProvider => `${hosts.unhygienix}/v2/question/query-language/${storageProvider}`,
  queryPackages: (languageId) => `${hosts.unhygienix}/v2/query-language/package/${languageId}/organization/${orgId}`,
  explain: `${hosts.unhygienix}/v2/question/explain`,
  describe: `${hosts.unhygienix}/v2/question/describe`,
  sharedPartners: (cleanRoomId, questionId) => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-question/${questionId}/result-share`,
  shareResults: (cleanRoomId, questionId) => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-question/${questionId}/result-share`,
  improveQueryWithAI: `${hosts.unhygienix}/v2/question/improve`,
  validateOutput: `${hosts.unhygienix}/v2/question/populate`,
  getGenAIResult: `${hosts.unhygienix}/v2/question/ask`,
  computeCapacity: questionId => `${hosts.unhygienix}/question/${questionId}/compute-capacity`,
  crqParameters: cleanRoomQuestionId => `${hosts.unhygienix}/v2/clean-room-question-parameters/${cleanRoomQuestionId}`,
  questionVersions: questionId => `${hosts.unhygienix}/organization/${orgId}/question/${questionId}/versions`,
  createAsset: (cleanRoomId, questionId) => `${hosts.unhygienix}/v2/organization/${orgId}/clean-room/${cleanRoomId}/question/${questionId}/asset`,
});

export const questionManagement = {
  accAvailableQuestions: (accId) => `${hosts.unhygienix}/question-management/account/${accId}/list`,
  accListFilters: (accId) => `${hosts.unhygienix}/question-management/account/${accId}/filters`,
  crQProvisionedListFilters: (orgId) => `${hosts.unhygienix}/question-management/organization/${orgId}/filters`,
  crQProvisioned: (orgId, qId) => `${hosts.unhygienix}/question-management/organization/${orgId}/question/${qId}/clean-rooms`,
  crProvisionedQuestions: (orgId) => `${hosts.unhygienix}/question-management/organization/${orgId}/clean-room/provisioned-list`,
  availableEntities: (orgId, qIDs) => `${hosts.unhygienix}/question-management/organization/${orgId}/available-entities${qIDs}`,
  deprovisionQuestion: (entityType, entityID, qId) => `${hosts.unhygienix}/question-management/${entityType}/${entityID}/question/${qId}/deprovision`,
  orgAvailableQuestions: (accId, orgId) => `${hosts.unhygienix}/question-management/account/${accId}/organization/${orgId}/available-list`,
  orgListFilters: (accId, orgId) => `${hosts.unhygienix}/question-management/account/${accId}/organization/${orgId}/filters`,
  orgProvisionedQuestions: (accId, orgId) => `${hosts.unhygienix}/question-management/account/${accId}/organization/${orgId}/provisioned-list`,
  platformAvailableQuestions: `${hosts.unhygienix}/question-management/list`,
  platformListFilters: `${hosts.unhygienix}/question-management/filters`,
  provisionAccQuestions: (accId) => `${hosts.unhygienix}/question-management/account/${accId}/provision`,
  provisionedQuestionAccAndOrg: (qId) => `${hosts.unhygienix}/question-management/question/${qId}/accounts-organizations`,
  provisionedQuestionEntities: (orgId, qId) => `${hosts.unhygienix}/question-management/organization/${orgId}/question/${qId}/provisioned-entities`,
  provisionedQuestionOrg: (accId, qId) => `${hosts.unhygienix}/question-management/question/${qId}/account/${accId}/organizations`,
  provisionOrgQuestions: (accId, orgId) => `${hosts.unhygienix}/question-management/account/${accId}/organization/${orgId}/provision`,
  provisionQuestions: `${hosts.unhygienix}/question-management/provision`,
  questionDetails: (orgId, qId) => `${hosts.unhygienix}/question-management/organization/${orgId}/question/${qId}`,
  requestQuestion: (crId, qId) => `${hosts.unhygienix}/question-management/CLEAN_ROOM/${crId}/question/${qId}/request`,
};

export const snowflake = orgId => ({
  dataConnectionFields: id => `${hosts.forebitt}/v2/organization/${orgId}/snowflake-data-connection/${id}/fields`,
  snowflakeCliVersionAll: `${hosts.primage}/snowflake/cli/versions/allOrgs`,
});

export const syntheticDatasets = orgId => ({
  list: `${hosts.armorica}/v2/organization/${orgId}/sandbox-dataset/list`,
  create: `${hosts.armorica}/v2/organization/${orgId}/sandbox-dataset`,
});

export const userLists = orgId => ({
  changeStatus: id => `${hosts.bucolix}/organization/${orgId}/userlist/${id}/status`,
  delete: id => `${hosts.bucolix}/organization/${orgId}/userlist/${id}`,
  detail: id => `${hosts.bucolix}/organization/${orgId}/userlist/${id}`,
  detailRuns: (id, startDate) => `${hosts.bucolix}/organization/${orgId}/userlist/${id}/trend${buildQueryString({ startDate })}`,
  list: `${hosts.bucolix}/organization/${orgId}/userlist/list`,
  reprocess: id => `${hosts.bucolix}/organization/${orgId}/userlist/${id}/reprocess`,
  save: `${hosts.bucolix}/organization/${orgId}/userlist`,
  summary: `${hosts.bucolix}/organization/${orgId}/userlist/summary`,
  userListFilters: `${hosts.bucolix}/organization/${orgId}/userlist/filters`,
});

export const customization = orgId => ({
  list: `${hosts.gomoonraker}/organization/${orgId}/customization`,
  create: `${hosts.gomoonraker}/organization/${orgId}/customization/create`,
  uploadLogo: `${hosts.gomoonraker}/customization/upload_logo`,
  cleanRoom: crId => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/customization`,
});


export const organizationParameters = orgId => ({
  list: `${hosts.gomoonraker}/organization/${orgId}/parameters`,
  listWithCategory: `${hosts.gomoonraker}/organization/${orgId}/parameters-with-category`,
  allowedListWithCategory: (useCase) => `${hosts.gomoonraker}/organization/${orgId}/use-case/${useCase}/allowed-parameters-with-category`,
  create: `${hosts.gomoonraker}/organization/${orgId}/parameters`,
  update: (paramName) => `${hosts.gomoonraker}/organization/${orgId}/parameters/${paramName}`,
  delete: (paramName) => `${hosts.gomoonraker}/organization/${orgId}/parameters/${paramName}`,
});

export const flow = orgId => ({
  create: () => `${hosts.unhygienix}/organization/${orgId}/flow`,
  createFlowRun: (cleanRoomId, flowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${flowId}/run`,
  deleteEdge: (flowId, edgeId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/edge/${edgeId}`,
  createEdge: (flowId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/edge`,
  createNode: (flowId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/node`,
  flowVersions: (flowId, offset = 0, limit = 25) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/versions?offset=${offset}&limit=${limit}`,
  updateNode: (flowId, nodeID) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/node/${nodeID}`,
  delete: (flowId, crId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}?cleanRoomID=${crId}`,
  datasets: (cleanRoomId, flowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${flowId}/datasets`,
  saveDatasets: (cleanRoomId, crFlowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${crFlowId}/datasets`,
  dataTypeOptions: (cleanRoomId, flowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${flowId}/data-type-options`,
  crFlowNodeOutputs: (crId, crFlowId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-flow/${crFlowId}/run/${runId}/output-nodes`,
  crNodeReportOutput: (crId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-flow-run/${runId}/report`,
  crNodeTableOutput: (crId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-flow-run/${runId}/table`,
  crFlowRunReportFilters: (crId, runId, nodeID) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-flow-run/${runId}/report/filters${buildQueryString({ nodeID })}`,
  details: (crFlowId, crId, version) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-flow/${crFlowId}?version=${version ?? -1}`,
  edge: (cleanRoomId, flowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/flow/${flowId}/edge`,
  updateEdge: (flowId, edgeId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/edge/${edgeId}`,
  fetchEdge: (flowId, edgeId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/edge/${edgeId}`,
  list: (cleanRoomId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/list`,
  listFilters: (cleanRoomId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/sorts`,
  nodes: (cleanRoomId, flowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/flow/${flowId}/nodes`,
  nodeDetails: (flowId, nodeId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/node/${nodeId}/details`,
  update: (flowId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}`,
  runParameters: (cleanRoomId, flowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${flowId}/run-parameters`,
  runList: (cleanRoomId, crFlowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${crFlowId}/run/list`,
  deleteCrFlowRun: (cleanRoomId, crFlowId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${crFlowId}/run/${runId}`,
  runFilters: (flowId) => `${hosts.unhygienix}/organization/${orgId}/flow/${flowId}/clean-room-flow-runs/sorts`,
  nodeAccess: (cleanRoomId, flowId, nodeId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${flowId}/node/${nodeId}/access`,
  macros: (cleanRoomId, crFlowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${crFlowId}/flow-macros`,
  macroMappings: (cleanRoomId, crFlowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${crFlowId}/macros-and-mappings`,
  flowMacroMappings: (cleanRoomId, crFlowId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${crFlowId}/macros-and-mappings`,
  newCachedFlowRun: (cleanRoomId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow-run/${runId}/new-cached-run`,
  startCachedFlowRun: (cleanRoomId, crFlowId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${crFlowId}/run/${runId}/replay`,
  resumeCachedFlowRun: (cleanRoomId, crFlowId, runId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-flow/${crFlowId}/run/${runId}/resume`,
});

export const alertBuilder = orgId => ({
  alertChat: (cleanRoomId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/alerts/chat`,
  completeChat: (cleanRoomId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/alerts/complete_chat`,
  alertsRequestFilters: (cleanRoomId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/alerts/chat/filters`,
  alertsRequest: (cleanRoomId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/alerts/chat/list`,
  alertChatDownload: (cleanRoomId, chatID) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/alerts/chat/${chatID}/download`,
});

export const datasetAnalysisRule = (orgId) => ({
  list: (cleanRoomId, datasetId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/dataset/${datasetId}/analysis-rule`,
  one: (cleanRoomId, datasetId, analysisRuleId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/dataset/${datasetId}/analysis-rule/${analysisRuleId}`,
  create: (cleanRoomId, datasetId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/dataset/${datasetId}/analysis-rule`,
  update: (cleanRoomId, datasetId, analysisRuleId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/dataset/${datasetId}/analysis-rule/${analysisRuleId}`,
  delete: (cleanRoomId, datasetId, analysisRuleId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/dataset/${datasetId}/analysis-rule/${analysisRuleId}`,
  runStatus: (cleanRoomId, crQId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${cleanRoomId}/clean-room-question/${crQId}/analysis-rule/run/status`,
});

export const supportMode = {
  upgrade: `${hosts.gomoonraker}/user/upgrade-user-type`,
  downgrade: `${hosts.gomoonraker}/user/downgrade-user-type`,
};

export const organizationIdentity = (orgId) => ({
  add: `${hosts.primage}/organization/${orgId}/domain-identifier`,
  update: `${hosts.primage}/organization/${orgId}/domain-identifier`,
  list: `${hosts.primage}/organization/${orgId}/domain-identifier/list`,
  one: (id) => `${hosts.primage}/organization/${orgId}/domain-identifier/${id}`,
  credentialsSource: `${hosts.primage}/credential/lookup/source`,
});

export const userDefinedFunctions = (orgId) => ({
  udfTypes: `${hosts.forebitt}/organization/${orgId}/udf-job-types/list`,
  listOrgCredentials: (udfType) => `${hosts.primage}/credential/organization/${orgId}/udf-type/${udfType}/list`,
  udfParameters: (udfTypeID) => `${hosts.forebitt}/organization/${orgId}/udf-job-parameters/${udfTypeID}`,
  list: `${hosts.forebitt}/organization/${orgId}/udf-jobs/list`,
  create: `${hosts.forebitt}/organization/${orgId}/udf-jobs`,
  one: (udfID) => `${hosts.forebitt}/organization/${orgId}/udf-jobs/${udfID}`,
  delete: (udfID) => `${hosts.forebitt}/organization/${orgId}/udf-jobs/${udfID}/delete`,
  crUdfList: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/udf-jobs/list`,
  crUdfConfigure: (crId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/udf-jobs`,
  crUdfReconfigure: (crId, crUdfID) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/udf-jobs/${crUdfID}`,
  crqUdfList: (crId, stage) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question-udfs/stage/${stage}/list`,
  crQuestionUdf: (crId, crqId) => `${hosts.unhygienix}/organization/${orgId}/clean-room/${crId}/clean-room-question/${crqId}/clean-room-question-udfs`,
});

export const trustCenter = {
  userList: (accountID) => `${hosts.unhygienix}/trust-center/users/account/${accountID}/list`,
  userFilter: (accountID) => `${hosts.unhygienix}/trust-center/users/account/${accountID}/filters`,
  userDownload: (accountID) => `${hosts.unhygienix}/trust-center/users/account/${accountID}/list/download`,
  datasetsList: (accountID) => `${hosts.unhygienix}/trust-center/datasets/account/${accountID}/list`,
  datasetsFilter: (accountID) => `${hosts.unhygienix}/trust-center/datasets/account/${accountID}/filters`,
  datasetsDownload: (accountID) => `${hosts.unhygienix}/trust-center/datasets/account/${accountID}/list/download`,
};

export const snowflakeAccountUsage = {
  allAccountSpendStats: `${hosts.primage}/snowflake/organization/all-account-spend-stats`,
  allAccountSpendSummary: `${hosts.primage}/snowflake/organization/all-account-spend-summary`,
  singleAccountUsageSummary: `${hosts.primage}/snowflake/organization/single-account-usage-summary`,
};
