import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import {
  Alert,
  Autocomplete, Divider, Skeleton, Stack, TextField, Typography,
} from '@mui/material';
import {
  HButton, HDialog, HDialogActions, HDialogContent, HDialogTitle,
} from 'BaseComponents';
import { useCreateExportJob } from 'hooks/activationAPI';
import { useFetchOrgPartnerAccounts } from 'hooks/activationChannelsAPI';
import { useFetchQuestionActivationOptions } from 'api/questions';
import { usePrepareTableForAssets } from 'api/questions/usePrepareTableForAssets';
import { useSelector } from 'react-redux';


const SelectRampIdModal = ({
  crId, question, onClose,
}) => {
  const [selectedRampIdColumn, setSelectedRampIdColumn] = useState({
    name: question?.AssetRampIDColumnName,
  });
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    isSuccess,
    isFetching,
    isError,
    data: questionActivationOptions,
  } = useFetchQuestionActivationOptions(
    crId, question?.ID,
  );

  const {
    data: partnerAccount,
  } = useFetchOrgPartnerAccounts(orgId, {
    filters: [
      { attribute: 'Partner', values: ['LR-Activation'] },
    ],
    sortBy: {},
    limit: 1,
    offset: 0,
    search: '',
  });

  const {
    mutateAsync: prepareTableForAssets,
    isLoading: isPreparingTableForAssets,
  } = usePrepareTableForAssets();

  const {
    mutateAsync: createExportJob,
    isLoading: isCreatingExportJob,
  } = useCreateExportJob();

  // Handle modal close
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleRampIdChange = (event, val) => setSelectedRampIdColumn(val);

  // Handle data submit
  const onSubmit = async () => {
    await prepareTableForAssets({
      cleanRoomId: crId,
      questionId: question?.ID,
      payload: {
        tableName: '',
        rampID: selectedRampIdColumn.name,
      },
    });

    // create export job if question is not an asset
    if (!question.isAsset) {
      const partnerAccountID = partnerAccount?.data?.partnerAccounts[0]?.ID;
      if (partnerAccountID) {
        await createExportJob({
          cleanRoomID: crId,
          cleanRoomQuestionID: question?.ID,
          partnerAccountIDs: [partnerAccountID],
          exportParameters: [],
        });
      }
    }
    handleClose();
  };

  return (
    <HDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      data-testid='share-results-modal'
    >
      <HDialogTitle onClose={handleClose}>
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='h5'>Select the Ramp ID Field</Typography>
          </Stack>
        </Stack>
      </HDialogTitle>
      <Divider />
      <HDialogContent>
        {
          isFetching
            ? (
              <Skeleton variant='rectangular' height={40} />
            )
            : (
              <>
                {
                  isSuccess ? (
                    <Autocomplete
                      size='small'
                      id='select-ramp-id-column'
                      data-testid='select-ramp-id-column'
                      options={questionActivationOptions?.identityField}
                      value={selectedRampIdColumn || null}
                      getOptionLabel={option => option.name}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size='small'
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      onChange={handleRampIdChange}
                    />
                  ) : isError && (
                    <Alert severity='error'>
                      <Typography variant='body2'>
                        Error fetching options, please try again later.
                      </Typography>
                    </Alert>
                  )
                }
              </>
            )
        }
      </HDialogContent>
      <Divider />
      <HDialogActions sx={{ my: 2 }}>
        <HButton
          variant='outlined'
          data-testid='cancel-button'
          onClick={handleClose}
        >
          Cancel
        </HButton>
        <LoadingButton
          variant='contained'
          color='primary'
          disabled={!selectedRampIdColumn}
          data-testid='share-button'
          loading={isPreparingTableForAssets || isCreatingExportJob}
          onClick={onSubmit}
        >
          Confirm
        </LoadingButton>
      </HDialogActions>
    </HDialog>
  );
};

export default SelectRampIdModal;
