import { axiosInstance } from 'utils/http';
import { question } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const usePrepareTableForAssets = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    ({ cleanRoomId, questionId, payload }) => axiosInstance.post(
      question(orgId).createAsset(cleanRoomId, questionId),
      payload,
    ),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Successfully updated Ramp ID Field', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};
