import Grid from '@mui/material/Grid';
import React, { memo } from 'react';
import { FlowNodeContextProvider } from 'pages/Flow/Nodes/FlowNode/FlowNodeContext';
import { flowPalette } from 'pages/Flow/HelpFlow/constants';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';


const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => ![
    'selected',
    'nodeSize',
    'type',
    'nodeLevel',
    'isFlowCachedMode',
    'selectedStartLevel',
    'selectedPauseLevel',
  ].includes(prop),
})(({
  theme,
  nodeSize,
  type,
  selected,
  nodeLevel,
  isFlowCachedMode,
  selectedStartLevel,
  selectedPauseLevel,
}) => {
  const flowCachedBorder = isFlowCachedMode && nodeLevel > selectedStartLevel && '2px solid';
  const border = flowCachedBorder || (nodeSize === 'MD' ? '1px solid' : '');
  const selectedBackground = nodeSize === 'MD' ? habuColors.neutral[10] : habuColors.neutral[5];
  const disabledLevelBackground = isFlowCachedMode
    && (nodeLevel === 0 || nodeLevel < selectedStartLevel)
    && habuColors.neutral[10];
  const disabledLevelBorderColor = isFlowCachedMode
    && (nodeLevel === 0 || nodeLevel < selectedStartLevel)
    && theme.palette.disabled.main;
  const cachedNodeToRunBorderColor = isFlowCachedMode
    && nodeLevel > selectedStartLevel
    && (selectedPauseLevel > 0 ? nodeLevel < selectedPauseLevel : !Number.isNaN(selectedPauseLevel))
    && theme.palette.primary.light;

  const borderColor = cachedNodeToRunBorderColor || (nodeSize === 'MD' && type ? flowPalette(theme, type).borderColor : '');
  const pauseLevelBorderColor = isFlowCachedMode && selectedPauseLevel === nodeLevel;
  const selectedLevelBackground = selectedPauseLevel === nodeLevel
    ? theme.palette.success['x-light']
    : theme.palette.background.paper;

  return {
    border,
    borderColor: disabledLevelBorderColor || pauseLevelBorderColor || borderColor,
    maxWidth: nodeSize === 'MD' ? '400px' : '150px',
    padding: nodeSize === 'MD' ? '' : '8px',
    ...(selected ? {
      borderRadius: isFlowCachedMode ? 5 : 2,
      border: isFlowCachedMode ? '2px solid' : border,
      borderColor: isFlowCachedMode ? theme.palette.primary.main : borderColor,
      background: isFlowCachedMode ? selectedLevelBackground : selectedBackground,
      boxShadow: '0px 4px 10px 0px rgba(69, 72, 77, 0.12), 0px 1px 2px 0px rgba(59, 66, 77, 0.20)',
      borderTop: nodeSize === 'SM' && type ? `2px solid ${flowPalette(theme, type).borderColor}` : '',
    } : {
      borderRadius: isFlowCachedMode ? 5 : '',
      background: disabledLevelBackground || (nodeSize === 'MD' ? theme.palette.background.paper : ''),
    }),
  };
});


export const FlowNode = memo(({
  children,
  selected,
  type,
  customNodeSize,
  nodeLevel,
  isFlowCachedMode,
  selectedStartLevel,
  selectedPauseLevel,
}) => {
  const canvasNodeSize = useSelector(state => state.flow.nodeSize);
  const nodeSize = customNodeSize || canvasNodeSize;

  return (
    <FlowNodeContextProvider value={{ selected, type }}>
      <StyledGrid
        container
        data-testid='flow-node'
        type={type}
        nodeSize={nodeSize}
        selected={selected}
        isFlowCachedMode={isFlowCachedMode}
        selectedStartLevel={selectedStartLevel}
        selectedPauseLevel={selectedPauseLevel}
        nodeLevel={nodeLevel}
      >
        {children}
      </StyledGrid>
    </FlowNodeContextProvider>
  );
});
