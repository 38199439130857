import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { QuestionsPageLayout } from 'pages/CleanRooms/Questions/QuestionsPageLayout';
import { cleanRoomPermissions, productPerm } from 'utils/appConstants';
import { useFetchCleanRoomAvailableQuestionList } from 'hooks/questionAPIs';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const Questions = () => {
  const { crId } = useParams();
  const [tabValue, updateTab] = React.useState('currentQuestions');
  const authorizedMQ = PermissionService.isAuthorized([cleanRoomPermissions.manageQuestions]);
  const isTaxonomyEnabled = PermissionService.isProductEnabled([productPerm.CleanRoomTaxonomy]);
  const isCRTemplate = useSelector(state => state?.cleanRooms?.detail?.isTemplate ?? false);

  let count = 0;
  let recQuestionsFetched = false;

  // Only if this permission is present, should we fetch the recommended questions
  if (authorizedMQ) {
    const {
      data: { count: fetchedCount = 0 },
      mutate: fetchQuestionList,
      isSuccess: fetchedRecQuestions,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useFetchCleanRoomAvailableQuestionList(crId);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      fetchQuestionList({ isSuggested: true });
    }, [fetchQuestionList]);

    count = fetchedCount;
    recQuestionsFetched = fetchedRecQuestions;
  }

  return (
    <>
      <Tabs
        indicatorColor='primary'
        onChange={(e, newValue) => updateTab(newValue)}
        value={tabValue}
      >
        <Tab
          label='Current Questions'
          value='currentQuestions'
          data-testid='current-question-tab'
        />

        {authorizedMQ && (
          <Tab
            label='Available Questions'
            value='availableQuestions'
            data-testid='available-question-tab'
          />
        )}
        {authorizedMQ && recQuestionsFetched && count > 0 && (
          <Tab
            label='Recommended Questions'
            value='recommendedQuestions'
            data-test='recommended-question-tab'
          />
        )}
        {isTaxonomyEnabled && <Tab label='Taxonomy' value='taxonomy' data-testid='taxonomy-tab' />}
        {isCRTemplate && <Tab label='Provision Questions' value='templateProvision' data-testid='template-provision-tab' />}
      </Tabs>
      <Box mt={3}>
        <QuestionsPageLayout
          authorizedMQ={authorizedMQ}
          tabValue={tabValue}
          updateTab={updateTab}
        />
      </Box>
    </>
  );
};
