import adhLogo from 'assets/partners/google.svg';
import amcLogo from 'assets/partners/amazon.svg';
import awsLogo from 'assets/partners/aws.svg';
import confidentialComputingLogo from 'assets/confidential-computing.svg';
import databricksLogo from 'assets/partners/databricks.svg';
import facebookLogo from 'assets/partners/facebook.svg';
import gcpLogo from 'assets/partners/google-cloud-big-query.svg';
import linkedInLogo from 'assets/partners/linkedin.svg';
import liveRampHybrid from 'assets/partners/liveramp-hybrid.svg';
import snowflakeLogo from 'assets/partners/snowflake.svg';
import { habuColors } from 'Theme';


// TODO: deprecate DV-6093
export const ACTIVATION_TYPE = {
  QUESTION: 'question',
  QUESTION_RUN: 'questionRun',
};

export const Aggregations = [
  { value: 'SUM', name: 'Sum' },
  { value: 'AVG', name: 'Avg' },
];

export const ChartSubTypeMap = {
  AREA: { key: 'AREA', label: 'Area' },
  BAR: { key: 'BAR', label: 'Bar' },
  COLUMN: { key: 'COLUMN', label: 'Column' },
  LINE: { key: 'LINE', label: 'Line' },
};

export const CleanRoomDatasetStages = {
  COMPLETE: {
    key: 'COMPLETE', label: 'Completed', color: habuColors.signal.success[10], stageTextColor: habuColors.signal.success[80],
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS', label: 'In Progress', color: habuColors.neutral[10], stageTextColor: habuColors.neutral[60],
  },
  PENDING: {
    key: 'PENDING', label: 'Pending', color: habuColors.primary[20], stageTextColor: habuColors.primary[90],
  },
  FAILED: {
    key: 'FAILED', label: 'Failed', color: habuColors.signal.alert[10], stageTextColor: habuColors.signal.alert[80],
  },
  UNKNOWN: { key: 'UNKNOWN', label: '-', stageTextColor: '' },
};

export const CLEAN_ROOM_STAGES = {
  COMPLETE: {
    key: 'COMPLETE', label: 'Completed', color: '#E2F8D9', stageTextColor: '#1B5E20',
  },
  READY: {
    key: 'READY', label: 'Ready', color: '#FFF9D7', stageTextColor: '#FFB800',
  },
  PROVISIONING: {
    key: 'PROVISIONING', label: 'Provisioning', color: '#FFF9D7', stageTextColor: '#FFB800',
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS', label: 'In Progress', color: '#EEEEEE', stageTextColor: '#696969',
  },
  PENDING: {
    key: 'PENDING', label: 'Pending', color: '#FFF9D7', stageTextColor: '#FFB800',
  },
  FAILED: {
    key: 'FAILED', label: 'Failed', color: '#FCE3D5', stageTextColor: '#C62828',
  },
  UNKNOWN:
  {
    key: 'UNKNOWN', label: '-', color: '', stageTextColor: '',
  },
};

export const CLEAN_ROOM_STATUS = {
  EXPIRED: {
    key: 'EXPIRED', label: 'Expired', color: '#FFEBD4', statusTextColor: '#FF8C00',
  },
};

export const CleanRoomInvitationStatus = {
  ACCEPTED: { key: 'ACCEPTED', label: 'Accepted', color: 'green' },
  COMPLETED: { key: 'COMPLETED', label: 'Completed', color: 'green' },
  DECLINED: { key: 'DECLINED', label: 'Declined', color: 'green' },
  IN_PROGRESS: { key: 'IN_PROGRESS', label: 'In Progress' },
  PENDING: { key: 'PENDING', label: 'Pending' },
  STAGE_UNKNOWN: { key: 'STAGE_UNKNOWN', label: '-' },
  REQUEST_SENT_ADMIN: { key: 'REQUEST_SENT_ADMIN', label: 'Awaiting Admin Approval' },
  DECLINED_BY_ADMIN: { key: 'DECLINED_BY_ADMIN', label: 'Declined By Admin' },
};

export const CleanRoomTypes = {
  AMC_CLEAN_ROOM: 'AMC_CLEAN_ROOM',
  AWS_CLEAN_ROOM: 'AWS_CLEAN_ROOM',
  DATABRICKS_CR: 'DATABRICKS_CLEAN_ROOM',
  FAA_CLEAN_ROOM: 'FAA_CLEAN_ROOM',
  GOOGLE_ADH_CLEAN_ROOM: 'GOOGLE_ADH_CLEAN_ROOM',
  GOOGLE_CLOUD_BIG_QUERY_CLEAN_ROOM: 'GCP_BIG_QUERY_CLEAN_ROOM',
  HABU_CR: 'HABU_CLEAN_ROOM',
  HYBRID_V2_CLEAN_ROOM: 'HYBRID_V2_CLEAN_ROOM',
  LINKEDIN_CLEAN_ROOM: 'LINKEDIN_CLEAN_ROOM',
  SNOWFLAKE_CR: 'SNOWFLAKE_CLEAN_ROOM',
  HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM: 'HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM',
};

export const EXPORTABLE_CLEAN_ROOMS = [
  CleanRoomTypes.HABU_CR,
  CleanRoomTypes.HYBRID_V2_CLEAN_ROOM,
  CleanRoomTypes.AMC_CLEAN_ROOM,
  CleanRoomTypes.HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM,
  CleanRoomTypes.FAA_CLEAN_ROOM,
  CleanRoomTypes.LINKEDIN_CLEAN_ROOM,
];

export const MULTI_REGION_CLEAN_ROOMS = [
  CleanRoomTypes.HYBRID_V2_CLEAN_ROOM,
  CleanRoomTypes.GOOGLE_CLOUD_BIG_QUERY_CLEAN_ROOM,
];

export const REGION_LIST = {
  REGION_US: { key: 'REGION_US', label: 'US' },
  REGION_EU: { key: 'REGION_EU', label: 'EU' },
  REGION_AFRICA: { key: 'REGION_AFRICA', label: 'AFRICA' },
  REGION_APAC: { key: 'REGION_APAC', label: 'APAC' },
  REGION_UAE: { key: 'REGION_UAE', label: 'UAE' },
};

export const CLEAN_ROOMS_REGION_MAP = {
  [CleanRoomTypes.HYBRID_V2_CLEAN_ROOM]: [
    REGION_LIST.REGION_US, REGION_LIST.REGION_EU, REGION_LIST.REGION_APAC,
  ],
  [CleanRoomTypes.GOOGLE_CLOUD_BIG_QUERY_CLEAN_ROOM]: [
    REGION_LIST.REGION_US, REGION_LIST.REGION_AFRICA, REGION_LIST.REGION_EU,
  ],
  [CleanRoomTypes.HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM]: [
    REGION_LIST.REGION_US, REGION_LIST.REGION_EU,
  ],
};

export const SUPPORTED_CLOUD_TO_REGION_MAP = {
  CLOUD_AWS: [
    REGION_LIST.REGION_US,
    REGION_LIST.REGION_UAE,
  ],
  CLOUD_AZURE: [
    REGION_LIST.REGION_EU,
  ],
  CLOUD_GCP: [
    REGION_LIST.REGION_US, REGION_LIST.REGION_APAC,
  ],
};

export const SUB_REGION_LIST = {
  SUB_REGION_EAST1: { key: 'SUB_REGION_EAST1', label: 'East 1' },
  SUB_REGION_SOUTHEAST1: { key: 'SUB_REGION_SOUTHEAST1', label: 'South East 1' },
  SUB_REGION_ME_CENTRAL1: { key: 'SUB_REGION_ME_CENTRAL1', label: 'Middle East Central 1' },
};

export const CLOUD_TO_REGION_TO_SUBREGION_MAP = {
  CLOUD_AWS: {
    [REGION_LIST.REGION_US.key]: [
      SUB_REGION_LIST.SUB_REGION_EAST1.key,
    ],
    [REGION_LIST.REGION_UAE.key]: [
      SUB_REGION_LIST.SUB_REGION_ME_CENTRAL1.key,
    ],
  },
  CLOUD_AZURE: {
    [REGION_LIST.REGION_EU.key]: [],
  },
  CLOUD_GCP: {
    [REGION_LIST.REGION_US.key]: [
      SUB_REGION_LIST.SUB_REGION_EAST1.key,
    ],
    [REGION_LIST.REGION_APAC.key]: [
      SUB_REGION_LIST.SUB_REGION_SOUTHEAST1.key,
    ],
  },
};

export const SUPPORTED_CLOUD_LIST = {
  AWS: { key: 'AWS', label: 'AWS', value: 'CLOUD_AWS' },
  AZURE: { key: 'AZURE', label: 'Azure', value: 'CLOUD_AZURE' },
  GCP: { key: 'GCP', label: 'GCP', value: 'CLOUD_GCP' },
};

export const SUPPORTED_CLOUD_PARAM_MAP = {
  CLOUD_AWS: 'AWS',
  CLOUD_AZURE: 'Azure',
  CLOUD_GCP: 'GCP',
};

export const DefaultSnowflakeAccountKeys = {
  region: 'US-WEST',
  type: 'SNOWFLAKE_ACCOUNT',
};

export const EMPTY_DATASET_FIELD = {
  dataTypeCanonicalField: {
    ID: '', dataType: '', name: '', type: 'FIELD_TYPE_UNKNOWN', optional: false,
  },
  delimiter: '',
  fieldName: '',
  fieldIndex: 0,
  fieldType: 'STRING',
  isExcluded: false,
  isFilterable: false,
  isPii: false,
  isUserIDField: false,
  transformation: '',
  rename: '',
};


export const FilterTypes = {
  MULTI_SELECT: { label: 'Multi Select', key: 'MULTI_SELECT' },
  SINGLE_SELECT: { label: 'Single Select', key: 'SINGLE_SELECT' },
};

export const InputTypesMap = {
  DATE: { key: 'DATE', label: 'Date' },
  INTEGER: { key: 'INTEGER', label: 'Integer' },
  STRING: { key: 'STRING', label: 'String' },
  TIMESTAMP: { key: 'TIMESTAMP', label: 'Timestamp' },
  DOUBLE: { key: 'DOUBLE', label: 'Double' },
};

export const Ownership = {
  OWNER: { key: 'OWNER', label: 'Owner' },
  PARTNER: { key: 'PARTNER', label: 'Partner' },
  OWNERSHIP_UNKNOWN: { key: 'OWNERSHIP_UNKNOWN', label: 'Unknown' },
};

export const Parameters = {
  CROWD_SIZE: 'CROWD_SIZE',
  DATA_DECIBEL: 'DATA_DECIBEL',
  ENABLE_EXPORT: 'ENABLE_EXPORT',
  ENABLE_PAIR: 'ENABLE_PAIR',
  ENABLE_OPJA: 'ENABLE_OPJA',
  ENABLE_HABU_INTELLIGENCE: 'ENABLE_HABU_INTELLIGENCE',
  HABU_INTELLIGENCE_CR_GROUP_NAME: 'HABU_INTELLIGENCE_CR_GROUP_NAME',
  HABU_INTELLIGENCE_URL: 'HABU_INTELLIGENCE_URL',
  PARTNER_ID: 'PARTNER_ID',
  NUMBER_OF_DAILY_RUNS: 'NUMBER_OF_DAILY_RUNS',
  BQ_BILLING_PROJECT_ID: 'BQ_BILLING_PROJECT_ID',
  BQ_PRINCIPAL_FOR_OUTPUT_ACCESS: 'BQ_PRINCIPAL_FOR_OUTPUT_ACCESS',
  BQ_CR_SERVICE_ACCOUNT: 'BQ_CR_SERVICE_ACCOUNT',
  MAX_PRIVACY_LOSS: 'MAX_PRIVACY_LOSS',
  MAX_QUESTION_RUNS: 'MAX_QUESTION_RUNS',
  TIME_HORIZON: 'TIME_HORIZON',
  REGION: 'REGION',
  CLOUD: 'CLOUD',
  SUB_REGION: 'SUB_REGION',
  SNOWFLAKE_CLEAN_ROOM_WAREHOUSE: 'SNOWFLAKE_CLEAN_ROOM_WAREHOUSE',
  ENABLE_DATASET_MATERIALIZATION: 'ENABLE_DATASET_MATERIALIZATION',
};

export const PERMISSIONS = {
  [Ownership.OWNER.key]: [
    { value: 'OWNER_ADMIN', name: 'Admin' },
    { value: 'OWNER_READ_ONLY', name: 'Viewer' },
  ],
  [Ownership.PARTNER.key]: [
    { value: 'PARTNER_ADMIN', name: 'Admin' },
    { value: 'PARTNER_READ_ONLY', name: 'Viewer' },
  ],
};

export const PermsLabels = {
  OWNER_ADMIN: 'Admin',
  OWNER_READ_ONLY: 'Viewer',
  PARTNER_ADMIN: 'Partner Admin',
  PARTNER_READ_ONLY: 'Partner Viewer',
};

export const StorageProvidersMap = {
  AZURE: { key: 'AZURE', label: 'Microsoft Azure' },
  BIGQUERY: { key: 'BIGQUERY', label: 'Google Cloud: BigQuery' },
  FACEBOOK_AA: { key: 'FACEBOOK_AA', label: 'Facebook Advanced Analytics' },
  GCS: { key: 'GCS', label: 'Google Cloud Storage' },
  S3: { key: 'S3', label: 'Amazon S3' },
  S3_STANDARD: { key: 'S3_STANDARD', label: 'Standard S3' },
  SNOWFLAKE: { key: 'SNOWFLAKE', label: 'Snowflake' },
  SNOWFLAKE_CR: { key: 'SNOWFLAKE_CR', label: 'Snowflake Clean-room' },
  LINKEDIN_AZURE: { key: 'LINKEDIN_AZURE', label: 'LinkedIn Marketing Solution' },
};

export const VisualizationTypesMap = {
  HORIZONTAL_BAR_CHART: { key: 'HORIZONTAL_BAR_CHART', label: 'Grouped Bar Chart(Horizontal)' },
  VERTICAL_BAR_CHART: { key: 'VERTICAL_BAR_CHART', label: 'Grouped Bar Chart' },
  HEATMAP: { key: 'HEATMAP', label: 'Heatmap' },
  VERTICAL_BAR_LINE: { key: 'VERTICAL_BAR_LINE', label: 'One Bar Chart with one Line' },
  VERTICAL_GROUPED_BAR_LINE_CHART: { key: 'VERTICAL_GROUPED_BAR_LINE_CHART', label: 'Grouped Bar with one Line' },
  BAR_LINE_CHART: { key: 'BAR_LINE_CHART', label: 'Line Bar Chart' },
  TREEMAP_CHART: { key: 'TREEMAP_CHART', label: 'Tree Map Chart' },
  NO_DIMENSION_BAR_CHART: { key: 'NO_DIMENSION_BAR_CHART', label: 'No Dimension Bar Chart' },
  NO_CHART: { key: 'NO_CHART', label: 'No Chart' },
};

export const YAxisPlacement = {
  y0: { key: 'Y0', label: 'Default Left Y-Axis (y0)' },
  y1: { key: 'Y1', label: 'Default Right Y-Axis (y1)' },
  yLeft: { key: 'YLEFT', label: 'Independent Left Y-Axis' },
  yRight: { key: 'YRIGHT', label: 'Independent Right Y-Axis' },
};


export const UserListDefaultValues = {
  y0: 'Y0',
  TOTAL_COUNT: 'TOTAL_COUNT',
  INTEGER: 'INTEGER',
  TABLE_NAME: 'TABLE_NAME',
  STRING: 'STRING',
  EXTERNAL_ID: 'EXTERNAL_ID',
};

export const CLEAN_ROOM_LOGOS = {
  ADH: { key: 'GOOGLE_ADH_CLEAN_ROOM', label: 'Google ADH', logoSrc: adhLogo },
  AMC: { key: 'AMC_CLEAN_ROOM', label: 'Amazon Marketing Cloud', logoSrc: amcLogo },
  AWS: { key: 'AWS_CLEAN_ROOM', label: 'Amazon Web Services', logoSrc: awsLogo },
  Databricks: { key: 'DATABRICKS_CLEAN_ROOM', label: 'Databricks', logoSrc: databricksLogo },
  Facebook: { key: 'FAA_CLEAN_ROOM', label: 'Facebook Advance Analytics', logoSrc: facebookLogo },
  Google: { key: 'GCP_BIG_QUERY_CLEAN_ROOM', label: 'Google Cloud BigQuery', logoSrc: gcpLogo },
  HabuConfidentialComputing: { key: 'HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM', label: 'Confidential Computing', logoSrc: confidentialComputingLogo },
  Hybrid: { key: 'HABU_CLEAN_ROOM', label: 'Hybrid', logoSrc: liveRampHybrid },
  HybridV2: { key: 'HYBRID_V2_CLEAN_ROOM', label: 'Hybrid', logoSrc: liveRampHybrid },
  LinkedIn: { key: 'LINKEDIN_CLEAN_ROOM', label: 'LinkedIn', logoSrc: linkedInLogo },
  Legacy: { key: 'HABU_CLEAN_ROOM', label: 'Legacy', logoSrc: liveRampHybrid },
  Snowflake: { key: 'SNOWFLAKE_CLEAN_ROOM', label: 'Snowflake', logoSrc: snowflakeLogo },
};

export const CLEAN_ROOM_TYPES_MAP = {
  AMC_CLEAN_ROOM: 'AMC',
  AWS_CLEAN_ROOM: 'AWS',
  DATABRICKS_CLEAN_ROOM: 'Databricks',
  FAA_CLEAN_ROOM: 'Facebook',
  GCP_BIG_QUERY_CLEAN_ROOM: 'Google',
  GOOGLE_ADH_CLEAN_ROOM: 'ADH',
  HABU_CLEAN_ROOM: 'Hybrid',
  HYBRID_V2_CLEAN_ROOM: 'HybridV2',
  HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM: 'HabuConfidentialComputing',
  LINKEDIN_CLEAN_ROOM: 'LinkedIn',
  SNOWFLAKE_CLEAN_ROOM: 'Snowflake',
};

export const CLEAN_ROOM_TYPE_STORAGE_PROVIDER_MAP = {
  Hybrid: 'SNOWFLAKE',
  HybridV2: 'HYBRID_V2',
  Legacy: 'SNOWFLAKE',
  ADH: 'BIGQUERY',
  AMC: 'S3_STANDARD',
  Snowflake: 'SNOWFLAKE_CR',
  Facebook: 'FACEBOOK_AA',
  LinkedIn: 'LINKEDIN_AZURE',
  Databricks: 'DATABRICKS_CR',
  Google: 'BIGQUERY_CR',
  AWS: 'AWS_CR',
  HabuConfidentialComputing: 'HABU_CC',
};

export const CLEAN_ROOM_FOR_TRANSFORMATION = {
  HABU_CLEAN_ROOM: 'HABU_CLEAN_ROOM',
  LINKEDIN_CLEAN_ROOM: 'LINKEDIN_CLEAN_ROOM',
  HYBRID_V2_CLEAN_ROOM: 'HYBRID_V2_CLEAN_ROOM',
  HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM: 'HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM',
};

export const DATA_SOURCE_FOR_TRANSFORMATION = {
  'Azure Storage': 'Azure Storage',
  'Client AWS S3': 'Client AWS S3',
  'Google Cloud Big Query': 'Google Cloud Big Query',
  'Google Cloud Storage': 'Google Cloud Storage',
  'Google Cloud Storage(with SA)': 'Google Cloud Storage(with SA)',
  'Habu AWS S3': 'Habu AWS S3',
  'Local Upload': 'Local Upload',
  'Habu Custom AWS': 'Habu Custom AWS',
  'Habu Google Cloud Storage': 'Habu Google Cloud Storage',
  Salesforce: 'Salesforce',
  'LinkedIn Marketing Solution': 'LinkedIn Marketing Solution',
  'Snowflake Table': 'Snowflake Table',
  'CSV Catalog': 'CSV Catalog',
};

export const DATASET_TYPE_FOR_TRANSFORMATION = {
  'Ad Logs': 'Ad Logs',
  'CRM Data': 'CRM Data',
  'Conversion Events': 'Conversion Events',
  'Identity Graph': 'Identity Graph',
  Metadata: 'Metadata',
  'User Data': 'User Data',
  'User-Segment Map': 'User-Segment Map',
  'Transaction Data': 'Transaction Data',
  Generic: 'Generic',
  'Ad Engagement': 'Ad Engagement',
  'Ad Exposure': 'Ad Exposure',
  'Member Profile': 'Member Profile',
  'Campaign Data': 'Campaign Data',
};

export const cleanRoomQuestionPermissions = {
  VIEW_CODE: { name: 'VIEW_CODE', displayName: 'View Query/Code' },
  EDIT: { name: 'EDIT', displayName: 'Edit and Delete Question' },
  CLONE: { name: 'CLONE', displayName: 'Clone Question' },
  RUN: { name: 'RUN', displayName: 'Set up and Schedule Runs' },
  VIEW_RESULTS: { name: 'VIEW_RESULTS', displayName: 'View Reports and Output' },
};

export const crqPermissionsAccessLevels = {
  UNKNOWN_ACCESS: 'UNKNOWN_ACCESS',
  QUESTION_OWNER_ONLY: 'QUESTION_OWNER_ONLY',
  ALL_CLEAN_ROOM_PARTNERS: 'ALL_CLEAN_ROOM_PARTNERS',
};

export const permissionControlledMessage = 'This action is not permitted for this question. To regain access, please contact the Clean Room Partner';

export const CLEAN_ROOM_PERMISSIONS = {
  ActivateListQuestions: 'ActivateListQuestions',
  ProvisionActivationChannels: 'ProvisionActivationChannels',
};

export const DATASET_STATUS = {
  REQUIRED: 'CONFIGURATION_REQUIRED',
  CONFIGURED: 'CONFIGURED',
};

export const TEMPLATE_CLEAN_ROOM = {
  PartnerOrgID: 'TEMP_TEMPLATE_PARTNER_ORG_ID',
  PartnerOrgName: 'TEMPLATE PARTNER',
};

export const CleanRoomQuestionConfigParameters = {
  QUERY_FEDERATION: 'QUERY_FEDERATION',
  DISABLE_IDENTIFIER_PROJECTION: 'DISABLE_IDENTIFIER_PROJECTION',
};

export const CR_ACTIVATION_PERMISSION_OPERATIONS = {
  UNKNOWN: 'UNKNOWN',
  BUILD_SEGMENTS: 'BUILD_SEGMENTS',
  ACTIVATE: 'ACTIVATE',
};

export const DEFAULT_PERMISSIONS = [{
  displayName: cleanRoomQuestionPermissions.VIEW_CODE.displayName,
  permission: cleanRoomQuestionPermissions.VIEW_CODE.name,
  access: crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS,
  checked: false,
}, {
  displayName: cleanRoomQuestionPermissions.EDIT.displayName,
  permission: cleanRoomQuestionPermissions.EDIT.name,
  access: crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS,
  checked: false,
}, {
  displayName: cleanRoomQuestionPermissions.CLONE.displayName,
  permission: cleanRoomQuestionPermissions.CLONE.name,
  access: crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS,
  checked: false,
}, {
  displayName: cleanRoomQuestionPermissions.RUN.displayName,
  permission: cleanRoomQuestionPermissions.RUN.name,
  access: crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS,
  checked: false,
}, {
  displayName: cleanRoomQuestionPermissions.VIEW_RESULTS.displayName,
  permission: cleanRoomQuestionPermissions.VIEW_RESULTS.name,
  access: crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS,
  checked: false,
}];
