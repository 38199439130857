import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { FlowVersionHistoryContext } from './FlowVersionHistoryProvider';
import {
  HButton, HDialogTitle, HSpinner, HTablePagination,
} from 'BaseComponents';
import { cleanRoom } from 'utils/spaUrls';
import { habuColors } from 'Theme';
import { useFetchFlowVersionHistory } from 'hooks/Flow/flowAPI';
import { useHistory, useParams } from 'react-router-dom';



const FlowVersionHistoryDialog = () => {
  const history = useHistory();
  const { crId } = useParams();
  const { isOpen, closeModal, modalDetails } = React.useContext(FlowVersionHistoryContext);
  const [filters, setFilters] = useState({ offset: 0, limit: 15 });
  const {
    data, isSuccess, isLoading,
  } = useFetchFlowVersionHistory({
    flowId: modalDetails?.flowID,
    offset: filters.offset,
    limit: filters.limit,
  });

  const handleModalClose = () => {
    closeModal();
  };

  const onVersionSelect = (version) => {
    history.push(
      cleanRoom.flowBuilderWithVersion(crId, modalDetails.ID, modalDetails.flowID, version),
    );
  };
  const handlePaginationChange = (newFilters) => {
    setFilters(newFilters);
  };
  const count = data?.data.count || 0;
  return (
    <Dialog onClose={handleModalClose} open={isOpen} fullWidth maxWidth='md'>
      <HDialogTitle onClose={handleModalClose}>
        <Typography variant='h3'>Flow Version History</Typography>
      </HDialogTitle>
      <DialogContent dividers>
        {isLoading && <HSpinner />}
        {isSuccess && (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Flow Version</TableCell>
                    <TableCell>
                      <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>
                        Change Message
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data.versions.map((version) => (
                    <TableRow>
                      <TableCell>{`v${version.version}`}</TableCell>
                      <TableCell>
                        <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>
                          {version.message}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <HButton
                          variant='contained'
                          data-testid='select-button'
                          onClick={() => onVersionSelect(version.version)}
                        >
                          Use This Version
                        </HButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <HTablePagination
              count={count}
              filters={filters}
              onChange={handlePaginationChange}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FlowVersionHistoryDialog;
